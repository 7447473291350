<template>
  <h1>This is documentation</h1>
</template>

<script>
export default {
  name: 'Documentation'
}
</script>

<style scoped>

</style>

<template>
  <div class="px-2">
    <!-- controlling area   -->
    <div class="sticky top-0 bg-gray-50 border-b-4 pb-1.5">
      <div class="flex pt-2">
        <div class="flex flex-row items-center ml-4">
          <label id="listbox-label1" class="block text-3xl text-o_purple-100 font-semibold mr-2">
            Farm
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="isActive1 = !isActive1" type="button"
                      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
                {{ select_state1 }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>

            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="isActive1" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a @click="selectEvent"
                   v-for="f_option in farms" :key="f_option"
                   href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ f_option }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="searchBar2" class="flex flex-row items-center ml-4">
          <label id="listbox-label2" class="block text-3xl font-semibold text-o_purple-100 mr-2">
            Products
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="isActive2 = !isActive2" type="button"
                      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm
                      px-4 py-2 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100"
                      id="options-menu2" aria-haspopup="true" aria-expanded="true">
                {{ select_state2 }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>

            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="isActive2" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a @click="selectEvent2"
                   v-for="p_option in products" :key="p_option"
                   href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ p_option }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="searchBar3" class="flex flex-row items-center ml-4">
          <label id="listbox-label3" class="block text-3xl font-semibold text-o_purple-100 mr-2">
            Farmers
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="isActive3 = !isActive3" type="button"
                      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm
                      px-4 py-2 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100"
                      id="options-menu3" aria-haspopup="true" aria-expanded="true">
                {{ select_state3 }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>

            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="isActive3" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a @click="selectEvent3"
                   v-for="p_option in farmers" :key="p_option"
                   href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ p_option }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <button @click="ModeSwitch"
                type="button"
                class=" ml-3 font-semibold
              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
          {{ modeDisplay }}
        </button>
        <button @click="clear"
                v-if="clearActive"
                type="button"
                class=" ml-3 font-semibold
              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
          Clear
        </button>
        <button @click="BackAllGraph"
                v-if="node_selected"
                type="button"
                class=" ml-1.5  font-semibold
                inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
          Back
        </button>
        <button @click="DownLevel(nodeName)"
                v-if="node_selected"
                type="button"
                class=" ml-1.5 font-semibold
                inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
          Previous
        </button>
        <button @click="UpLevel(nodeName)"
                v-if="node_selected"
                type="button"
                class=" ml-1.5 font-semibold
                inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
          Next
        </button>
        <button @click="MaskGraph(nodeName)"
                v-if="node_selected"
                type="button"
                class=" ml-1.5 font-semibold
                inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
          Level
        </button>
      </div>
<!--      萬一之後要再多加一個下拉選單 or search bar那可以考慮用下面編排，但要清掉上面的Back/Previous/Next/Level button -->
<!--      <div class="flex flex-row-reverse mt-3 mr-1 mb-1.5">-->
<!--        <button @click="BackAllGraph"-->
<!--                v-if="node_selected"-->
<!--                type="button"-->
<!--                class=" ml-1.5  font-semibold-->
<!--                inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md-->
<!--                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2-->
<!--                focus:ring-offset-2 focus:ring-o_purple-100">-->
<!--          Back-->
<!--        </button>-->
<!--        <button @click="DownLevel(nodeName)"-->
<!--                v-if="node_selected"-->
<!--                type="button"-->
<!--                class=" ml-1.5 font-semibold-->
<!--                inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md-->
<!--                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2-->
<!--                focus:ring-offset-2 focus:ring-o_purple-100">-->
<!--          Previous-->
<!--        </button>-->
<!--        <button @click="UpLevel(nodeName)"-->
<!--                v-if="node_selected"-->
<!--                type="button"-->
<!--                class=" ml-1.5 font-semibold-->
<!--                inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md-->
<!--                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2-->
<!--                focus:ring-offset-2 focus:ring-o_purple-100">-->
<!--          Next-->
<!--        </button>-->
<!--        <button @click="MaskGraph(nodeName)"-->
<!--                v-if="node_selected"-->
<!--                type="button"-->
<!--                class=" ml-1.5 font-semibold-->
<!--                inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md-->
<!--                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2-->
<!--                focus:ring-offset-2 focus:ring-o_purple-100">-->
<!--          Level-->
<!--        </button>-->
<!--      </div>-->
    </div>
    <!-- content area   -->
    <div class="grid grid-cols-8 grid-rows-16 gap-1.5 mt-1.5 custom-h-inside">
      <!-- product info     -->
      <div class="row-span-full grid grid-rows-3  col-span-2 gap-1.5">
        <ul class="bg-white shadow-2xl text-left mx-2 my-2 text-2xl p-6 rounded-2xl row-span-1 overflow-auto">
          <li v-show="productCardActivate" class="text-o_purple-200 text-3xl font-semibold">{{ select_state2 }}</li>
          <li v-show="productCardActivate" class="text-o_purple-100 text-2xl font-semibold my-2" v-for="(item, key) in productCardInfo" :key="key">
            <span>{{ key }}</span> : <span>{{ item }}</span>
          </li>
        </ul>
        <div v-show="true" class="bg-white shadow-2xl rounded-2xl row-span-2 overflow-auto mr-2">
          <div class="bg-white p-6 mx-2 my-2 overflow-auto">
            <ul v-show="productCardActivate" class="bg-white text-2xl overflow-auto border-b-4 border-t_dark_brown mb-6">
              <li class="text-o_purple-200 text-3xl font-semibold mb-3">Blockchain Records
                <!-- Vue Chevron 箭頭元件使用說明: https://ispal.github.io/vue-chevron/ -->
                <span class="toggle inline" @click="toggle">
                  <vue-chevron class="inline-block ml-1"
                               :point-down="pointDown"
                               :duration="duration"
                               :thickness="thickness"
                               :angle="angle"
                               :round-edges="roundEdges"
                  />
                </span>
              </li>
              <li v-for="(bc_link, bc_name) in blockchainLinks" :key="bc_name" v-show="(blockchainRecordLinkActivate|false)" class="block text-o_pink text-xl font-semibold my-4 hover:underline">
                <a :href="bc_link" target="_blank">{{ bc_name }}</a>
              </li>
<!--              <li v-show="blockchainRecordLinkActivate ||false" class="block text-o_pink text-3xl font-semibold my-4 hover:underline">-->
<!--                <a :href="productChainLink" target="_blank">Blockchain Records 2</a>-->
<!--              </li>-->
<!--              <li v-show="blockchainRecordLinkActivate||false" class="block text-o_pink text-3xl font-semibold my-4 hover:underline">-->
<!--                <a :href="productChainLink" target="_blank">Blockchain Records 3 </a>-->
<!--              </li>-->
<!--              <li v-show="blockchainRecordLinkActivate||false" class="block text-o_pink text-3xl font-semibold my-4 hover:underline">-->
<!--                <a :href="productChainLink" target="_blank">Blockchain Records 3 </a>-->
<!--              </li>-->
<!--              <li v-show="blockchainRecordLinkActivate||false" class="block text-o_pink text-3xl font-semibold my-4 hover:underline">-->
<!--                <a :href="productChainLink" target="_blank">Blockchain Records 3 </a>-->
<!--              </li>-->
<!--              <li v-show="blockchainRecordLinkActivate||false" class="block text-o_pink text-3xl font-semibold my-4 hover:underline">-->
<!--                <a :href="productChainLink" target="_blank">Blockchain Records 3 </a>-->
<!--              </li>-->
            </ul>
            <ul v-show="operationCarbonActive" class="bg-white border-b-4 border-t_dark_brown mb-6">
              <h1 v-show="operationCarbonActive" class="text-o_purple-200 text-4xl font-semibold">
                {{ operationCarbon }}
              </h1>
              <h1 class="text-right mb-4 pt-4 ">
                  <span class="px-6 inline-flex text-3xl leading-15 font-semibold rounded-full bg-green-100 text-green-800">
                    Verified
                  </span>
              </h1>
              <span class="font-semibold text-3xl text-o_purple-100">CO<sub>2</sub></span><br>
              <div class="mt-2 pb-3.5 text-3xl text-o_purple-100">{{ this.carbonAmount }} kg</div>
            </ul>
            <h1 v-show="operationActive" class="text-o_purple-200 text-4xl font-semibold">
              {{ nodeName }}
            </h1>
            <ul v-show="operationActive" v-for="(record, i) in operationRecords" :key="i" class="text-o_purple-100 text-3xl mb-4  mt-1 pt-4 border-b-4 border-t_dark_brown">
              <h1 class="text-right">
                  <span class="px-6 inline-flex text-3xl leading-15 font-semibold rounded-full bg-green-100 text-green-800">
                    Verified
                  </span>
              </h1>
              <li v-for="(detail, k, i2) in record" :key="i2">
                <span class="font-semibold">{{ k }}</span><br>
                <div class="pb-3.5">{{ detail }}</div>
<!--                <span class="font-semibold"></span><br>-->
<!--                {{ detail }}-->
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--  graph canvas   -->
      <div class="col-span-6 row-span-full grid grid-rows-16 mt-0.5">
        <div class="rounded-2xl bg-white shadow-2xl col-span-full row-span-5 overflow-auto mx-2 mt-2 p-6">
          <h1 v-show="detailActive" class="text-o_purple-200 text-4xl font-semibold">
            {{ nodeName }}
          </h1>
          <div v-show="carbonActive" class="text-o_purple-100 text-3xl my-3">
            <span class="font-semibold">CO<sub>2</sub>:</span> {{ this.carbonAmount }} kg
          </div>
          <div v-show="detailTermActive" class="text-o_purple-100 text-3xl my-3">
            <span class="font-semibold">Terms in multi-languages(en/zh/ja):</span><br>
            {{ node_term_current.english }}/ {{ node_term_current.chinese }}/ {{ node_term_current.japanese }}
          </div>
          <ul v-show="detailActive" class="text-o_purple-100 text-3xl">
            <li v-for="(content, tag) in node_info_current" :key="tag">
              <span class="font-semibold">{{ tag.slice(0,1).toUpperCase() +tag.slice(1).toLowerCase() }}:</span><br>
              {{ content }}
            </li>
          </ul>
        </div>
        <div id="graph" class="rounded-2xl bg-white shadow-2xl mx-2 mt-6 col-span-full row-span-11 overflow-auto" ref="canvas"></div>
      </div>
<!--      <div v-if="false">暫定之後operationlist area</div>-->
    </div>
    <!--     尚無included into grid因此有gap差異-->
    <!--&lt;!&ndash;   width 0 調節aside   &ndash;&gt;111-->
    <div class="grid grid-cols-8 custom-h-inside rounded-2xl mx-2 mt-6 mb-8 bg-white shadow-2xl gap-6">
<!--      <img :src="img_source" alt="" />-->
      <aside v-show="imageGalleryActivate" class="col-span-2 row-span-full flex flex-col overflow-auto">
        <!--  之後改router link    -->
        <div v-for="(path, index) in img_s_sources" :key="path" @click="getIndexAndSelected(index, path)"
             class="flex flex-col p-3 ml-4 justify-center">
          <h1 class="items-end text-o_purple-200 text-3xl font-semibold">
            {{ img_info[index].title }}
          </h1>
          <img :src="path" alt="" class="inline-block w-5/6 items-center"/>
        </div>
      </aside>
      <main v-show="imageGalleryActivate" class="flex flex-col row-span-full col-span-6 overflow-auto ml-4">
        <div class="py-6 pr-6 ml-2">
          <h1 class="text-o_purple-200 text-3xl font-semibold">
            {{ current_img_title }}
          </h1>
          <p class="text-o_purple-100 text-2xl font-semibold mt-2">
            {{ current_img_detail }}
          </p>
        </div>
        <div id="myWindow" class="w-auto h-auto">
            <img id="myContent" :src="img_b_current" alt="" />
        </div>
      </main>
<!--      <span class="row-span-full">hide</span>-->
    </div>
  </div>
</template>

<script>
import G6 from '@antv/g6'
import WZoom from 'vanilla-js-wheel-zoom/src/wheel-zoom'
import Axios from 'axios'
import VueChevron from 'vue-chevron'
import Config from '../config/config'
let graphInstance = {}
export default {
  name: 'Platform',
  components: {
    VueChevron
  },
  data: function () {
    return {
      // mode有兩種 food(true) or carbon(false)，會影響圖的產生，之後會設計按鈕來切換mode
      mode: true,
      // 多增加一個mask狀態，來判定當前是否為mask狀態，這樣carbon切換比較容易，非mask就直接以nodeName重新產生graph全階層，反之以nodeName配合up_count、down_count去轉換
      mask: false,
      modeDisplay: 'Food',
      pointDown: true,
      thickness: 8,
      duration: 300,
      angle: 40,
      roundEdges: true,
      easing: function n (t) { return t },
      // 上面那幾個是for 'vue-chevron' component
      node_infos: {},
      node_info_current: {},
      node_term_current: {},
      img_info: this.$store.state.imageInfo,
      current_img_title: '',
      current_img_detail: '',
      img_s_sources: this.$store.state.imagePathS,
      img_b_sources: this.$store.state.imagePathB,
      unselected_classes: ['mt-2', 'p-3'],
      selected: true,
      unselected: false,
      showImageList: true,
      img_b_current: '',
      farms: [],
      products: [],
      productList: {},
      farmers: [],
      operationRecords: [],
      graphObject: {},
      select_state1: 'Please select',
      select_state2: 'Please select',
      select_state3: 'Please select',
      searchBar2: false,
      searchBar3: false,
      isActive1: false,
      isActive2: false,
      isActive3: false,
      clearActive: false,
      productCardActivate: false,
      imageGalleryActivate: false,
      blockchainRecordLinkActivate: true,
      blockchainLinks: {},
      productCardInfo: {},
      productChainLink: '',
      nodeActive: false,
      nodeName: '',
      // 下面這個很必要，不然每次你一選擇，food/carbon切換，他會以你當前點到的node去做繪圖(可實際上我們想要的是遮罩的那一個)
      maskNodeName: '',
      detailActive: false,
      detailTermActive: false,
      operationActive: false,
      operationCarbonActive: false,
      operationCarbon: '',
      carbonActive: false,
      carbonAmount: 0,
      imgContainerW: 0,
      imgContainerH: 0,
      current_graph: {},
      mask_graph: {},
      up_count: 0,
      down_count: 0,
      node_selected: false,
      language: {}
    }
  },
  created: function () {
    // 第一個選單farms農場選項，table格式會是第一欄是farm field第二欄是{"總季-productName":["季-農頻-productName" ...]}
    // 因此解析上要先把總季的key取出，再來iterate窮取後面的list
    this.farms = this.$store.state.farmlist
    this.node_infos = this.$store.state.nodeInfo
    // this.TestGetDataText()
    // this.TestGetDataJSON()
    // this.PostFormDataTest()
    // // console.log('finally setting', this.testRes)
  },
  mounted () {
    // this.img_s_sources = this.$store.state.imagePathB
    this.$store.state.current_graph_data = {}
    // 我個人覺得我這邊用nextTick有點怪，因為mounted階段已經有DOM了
    this.$nextTick(function () {
      // 動態抓取element寬高的function
      const width = this.$refs.canvas.clientWidth
      const height = this.$refs.canvas.clientHeight
      this.initG6(width, height, this.$store.state.current_graph_data)
    })
    // WZoom放在mounted hook合理，因為他要等DOM建立才能作用
    this.$nextTick(function () {
      WZoom.create('#myContent', {
        // type content: `image` - only one image, `html` - any HTML content
        type: 'image',
        // for type `image` computed auto (if width set null), for type `html` need set real html content width, else computed auto
        width: 1280,
        // for type `image` computed auto (if height set null), for type `html` need set real html content height, else computed auto
        height: 1024,
        // drag scrollable image
        dragScrollable: true,
        // options for the DragScrollable module
        dragScrollableOptions: {
          // smooth extinction moving element after set loose
          smoothExtinction: false,
          // callback triggered when grabbing an element
          onGrab: null,
          // callback triggered when moving an element
          onMove: null,
          // callback triggered when dropping an element
          onDrop: null
        },
        // minimum allowed proportion of scale
        minScale: null,
        // maximum allowed proportion of scale
        maxScale: 4,
        // image resizing speed
        speed: 10
      })
    })
  },
  computed: {},
  methods: {
    async ModeSwitch () {
      this.mode = !this.mode
      if (this.mode) {
        this.modeDisplay = 'Food'
      } else {
        this.modeDisplay = 'Carbon'
      }
      if (this.mask) {
        // 是以mask 的node name來去產圖，而非當前選到的node name(這種會造成他右去往下做mask)
        if (this.mode) {
          await this.MaskGetGraph(this.maskNodeName, this.up_count, this.down_count)
          graphInstance.read(this.mask_graph)
        } else {
          await this.MaskGetCarbonGraph(this.maskNodeName, this.up_count, this.down_count)
          graphInstance.read(this.mask_graph)
        }
      } else {
        // 非mask就是直接以select狀態去獲取graph，但要記得辨認是否為All farmers情況
        if (this.select_state3 === 'All Farmers') {
          await this.GetGraph(this.select_state2, 2, 3)
          graphInstance.read(this.current_graph)
        } else {
          await this.GetGraph(this.select_state3, 2, 3)
          graphInstance.read(this.current_graph)
        }
      }
    },
    // for 'vue-chevron' component使用
    toggle () {
      this.pointDown = !this.pointDown
      this.blockchainRecordLinkActivate = !this.blockchainRecordLinkActivate
    },
    // 取得選單內容from database
    async GetMenuList () {
      const menuListRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await menuListRequest.get('/menu/list').then(res => {
        this.select_state1 = 'Rice House'
        this.isActive1 = !this.isActive1
        this.productList = res.data
        const root = [Object.keys(this.productList)]
        let productList = []
        productList = productList.concat(...root)
        this.products = productList
      })
    },
    GetMenuList2 (root) {
      let farmerList = []
      // farmerList = farmerList.concat(root)
      if (this.productList[root].length > 0) {
        farmerList = farmerList.concat('All Farmers')
        farmerList = farmerList.concat(...this.productList[root])
      } else {
        farmerList = []
      }
      this.farmers = farmerList
    },
    async GetProductBcLink () {
      const dataForm = new FormData()
      if (this.select_state3 === 'All Farmers') {
        dataForm.append('product_name', this.select_state2)
      } else {
        dataForm.append('product_name', this.select_state3)
      }
      const graphRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await graphRequest.post('/menu/bc-links', dataForm).then(res => {
        this.blockchainLinks = res.data
      })
    },
    async GetGraph (name, uLevel, dLevel) {
      const dataForm = new FormData()
      dataForm.append('name', name)
      dataForm.append('u_level', uLevel)
      dataForm.append('d_level', dLevel)
      const graphRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      if (this.mode) {
        await graphRequest.post('/graph/graph', dataForm).then(res => {
          this.current_graph = { nodes: res.data.node_styles, edges: res.data.edges }
          // // console.log(this.current_graph)
        })
      } else {
        await graphRequest.post('/graph/graph-carbon', dataForm).then(res => {
          this.current_graph = { nodes: res.data.node_styles, edges: res.data.edges }
        })
      }
    },
    async MaskGetCarbonGraph (name, uLevel, dLevel) {
      const dataForm = new FormData()
      dataForm.append('name', name)
      dataForm.append('u_level', uLevel)
      dataForm.append('d_level', dLevel)
      // console.log('carbon mode')
      const graphRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await graphRequest.post('/graph/graph-carbon', dataForm).then(res => {
        this.mask_graph = { nodes: res.data.node_styles, edges: res.data.edges }
        // // console.log(this.current_graph)
      })
    },
    async MaskGetGraph (name, uLevel, dLevel) {
      const dataForm = new FormData()
      dataForm.append('name', name)
      dataForm.append('u_level', uLevel)
      dataForm.append('d_level', dLevel)
      // console.log('food mode')
      const graphRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await graphRequest.post('/graph/graph', dataForm).then(res => {
        this.mask_graph = { nodes: res.data.node_styles, edges: res.data.edges }
        // // console.log(this.current_graph)
      })
    },
    async MaskGraph (name) {
      this.mask = true
      this.up_count = 0
      this.down_count = 0
      this.maskNodeName = name
      if (this.mode) {
        await this.MaskGetGraph(name, 0, 0)
      } else {
        await this.MaskGetCarbonGraph(name, 0, 0)
      }
      graphInstance.read(this.mask_graph)
    },
    async UpLevel (name) {
      this.up_count += 1
      this.down_count += 1
      if (this.mode) {
        await this.MaskGetGraph(name, this.up_count, this.down_count)
      } else {
        await this.MaskGetCarbonGraph(name, this.up_count, this.down_count)
      }
      graphInstance.read(this.mask_graph)
    },
    async DownLevel (name) {
      this.up_count -= 1
      this.down_count -= 1
      if (this.mode) {
        await this.MaskGetGraph(name, this.up_count, this.down_count)
      } else {
        await this.MaskGetCarbonGraph(name, this.up_count, this.down_count)
      }
      graphInstance.read(this.mask_graph)
    },
    BackAllGraph () {
      this.mask = false
      // 沒有遮罩因此遮罩點名稱清空
      this.maskNodeName = ''
      graphInstance.read(this.current_graph)
    },
    async TestGetDataText () {
      // console.log('this is getData of method')
      const userRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'html/text' 表示要接收的是text
        headers: { Accept: 'html/text' }
      })
      await userRequest.get('/').then(res => {
        this.testRes = res.data
        // console.log(res)
        // console.log(res.data)
      })
    },
    // 下面是測試用2，get json
    // async TestGetDataJSON () {
    //   // console.log('this is getData of method')
    //   const userRequest = Axios.create({
    //     baseURL: 'https://www.for-paper-proxy.binyi.fun',
    //     // Accept: 'html/text' 表示要接收的是text
    //     headers: { Accept: 'application/json' }
    //   })
    //   await userRequest.get('/helloJson').then(res => {
    //     this.testRes2 = res.data
    //     // console.log(res)
    //     // console.log(res.data)
    //   })
    // },
    // 下面是測試用2，post data form並取得json
    async PostFormDataTest () {
      const dataForm = new FormData()
      dataForm.append('name', 'DanteA')
      dataForm.append('number', '1234')
      const userRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'html/text' 表示要接收的是text
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await userRequest.post('/getPersonData', dataForm).then(res => {
        this.testRes3 = res.data
        // console.log(res)
        // console.log(res.data)
      })
    },
    async nodeDetail (nodeName) {
      this.node_info_current = this.node_infos[nodeName].content
    },
    async showDetail (nodeName) {
      const dataForm = new FormData()
      dataForm.append('name', nodeName)
      this.operationCarbonActive = false
      let checkCarbonRelation
      let checkCarbonType
      const userRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // baseURL: 'http://localhost:8000',
        // Accept: 'html/text' 表示要接收的是text
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await userRequest.post('/graph/node', dataForm).then(res => {
        this.detailActive = true
        this.node_info_current = res.data.tags
        if (res.data.lans.language !== 0) {
          this.detailTermActive = true
          this.node_term_current = res.data.lans
        } else {
          this.detailTermActive = false
        }
        if (res.data.co2_kg !== -1) {
          this.carbonActive = true
          this.carbonAmount = res.data.co2_kg
        } else {
          this.carbonActive = false
        }
        checkCarbonType = res.data.type
        // console.log(checkCarbonType)
        if (checkCarbonType === 'detail') {
          checkCarbonRelation = res.data.relation_operation
          // console.log(checkCarbonRelation)
        }
      })
      if (checkCarbonType === 'detail') {
        checkCarbonType = 'detail'

        if (this.select_state3 === 'All Farmers') {
          this.carbonAmount = 0
          for (var i = 0; i < checkCarbonRelation.length; i++) {
            await this.getOperationCarbonAmount(checkCarbonRelation[i])
          }
          if (this.carbonAmount !== 0) {
            this.operationCarbonActive = true
          } else {
            this.operationCarbonActive = false
          }
          this.operationCarbon = checkCarbonRelation[0].split('_').pop()
        } else {
          const operationName = checkCarbonRelation.find(element => element.includes(this.select_state3))
          // console.log(operationName)
          await this.getOperationNodeCarbonInfo(operationName)
        }
      }
    },
    async getOperationCarbonAmount (nodeName) {
      const dataForm = new FormData()
      dataForm.append('name', nodeName)
      const userRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // baseURL: 'http://localhost:8000',
        // Accept: 'html/text' 表示要接收的是text
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await userRequest.post('/graph/node', dataForm).then(res => {
        this.detailActive = true
        if (res.data.co2_kg !== -1) {
          this.carbonAmount = this.carbonAmount + res.data.co2_kg
        } else {
          this.operationCarbonActive = false
        }
      })
    },
    async getOperationNodeCarbonInfo (nodeName) {
      const dataForm = new FormData()
      dataForm.append('name', nodeName)
      const userRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // baseURL: 'http://localhost:8000',
        // Accept: 'html/text' 表示要接收的是text
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await userRequest.post('/graph/node', dataForm).then(res => {
        this.detailActive = true
        if (res.data.co2_kg !== -1) {
          this.operationCarbonActive = true
          this.operationCarbon = nodeName
          this.carbonAmount = res.data.co2_kg
        } else {
          this.operationCarbonActive = false
        }
      })
    },
    async showOperation (code, operation) {
      const dataForm = new FormData()
      dataForm.append('code', code)
      dataForm.append('operation', operation)
      const operationRecordsRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'html/text' 表示要接收的是text
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await operationRecordsRequest.post('/operation/operations', dataForm).then(res => {
        this.operationRecords = res.data.operations
        if (this.operationRecords) {
          this.operationActive = true
        } else {
          this.operationActive = false
        }
      })
    },
    // showBlockchainLink() {
    //
    // },
    getIndexAndSelected (index) {
      this.showBig(index)
      // this.$refs[index].className = 'mt-2 p-3 block border-4 border-green-800 hover:shadow-2xl'
      // this.$refs[index].classList = ['mt-2', 'p-3', 'block', 'border-4', 'border-green-800', 'hover:shadow-2xl']
      // // console.log(this.$refs[index].class)
      this.current_img_title = this.img_info[index].title
      this.current_img_detail = this.img_info[index].detail
      return false
    },
    showBig (index) {
      this.img_b_current = this.img_b_sources[index]
    },
    selectEvent (event) {
      // const i = this.$store.state.graphInstance
      // i.read(this.$store.state.graphs.LMR.g2)
      this.GetMenuList()
      // this.select_state1 = event.target.innerText
      // this.isActive1 = !this.isActive1
      // this.products = this.$store.state.cardList[this.select_state1]
      this.searchBar2 = true
      this.searchBar3 = false
      this.operationCarbonActive = false
      this.select_state2 = 'Please select'
    },
    async selectEvent2 (event) {
      this.operationCarbonActive = false
      this.clearActive = true
      this.searchBar3 = true
      this.select_state2 = event.target.innerText
      this.isActive2 = !this.isActive2
      // 每次選取新的product要把node info清空包含carbon amount
      this.carbonActive = false
      if (this.productList[this.select_state2].length > 0) {
        this.productCardInfo = this.$store.state.productCardInfo[this.select_state2][0]
        this.productChainLink = this.$store.state.productCardInfo[this.select_state2][1]
        this.productCardActivate = true
        this.imageGalleryActivate = true
      }
      this.GetMenuList2(this.select_state2)
      this.select_state3 = 'Please select'
      this.productCardActivate = false
      this.imageGalleryActivate = false
      this.node_selected = false
      this.detailActive = false
      this.detailTermActive = false
      this.operationActive = false
      this.$store.state.current_graph_data = {}
      graphInstance.read(this.$store.state.current_graph_data)
    },
    async selectEvent3 (event) {
      this.clearActive = true
      this.operationCarbonActive = false
      this.select_state3 = event.target.innerText
      // 切換農夫也要清掉carbon amount顯示
      this.carbonActive = false
      // detailActive需要隨著切換農夫清掉、詞彙detailTermActive也要清掉
      this.detailActive = false
      this.detailTermActive = false
      // 左側的操作Action record理論上也要清空，畢竟不同farmer
      this.operationActive = false
      // node_selected disable會清掉mask操作按鈕(disable)
      this.node_selected = false

      this.isActive3 = !this.isActive3
      this.productCardInfo = this.$store.state.productCardInfo[this.select_state2][0]
      this.productChainLink = this.$store.state.productCardInfo[this.select_state2][1]
      this.productCardActivate = true
      this.imageGalleryActivate = true
      if (this.select_state3 === 'All Farmers') {
        this.nodeName = this.select_state2
        await this.GetGraph(this.select_state2, 2, 3)
        await this.GetProductBcLink()
      } else {
        this.nodeName = this.select_state3
        await this.GetGraph(this.select_state3, 2, 3)
        await this.GetProductBcLink()
      }
      // this.$store.state.current_graph_data = this.$store.state.graphs[this.select_state2]
      // graphInstance.read(this.$store.state.current_graph_data)
      // show grapph JSON
      graphInstance.read(this.current_graph)
      // // console.log(JSON.stringify(this.$store.state.graphs))
    },
    clear () {
      this.operationCarbonActive = false
      this.searchBar2 = false
      this.searchBar3 = false
      this.clearActive = false
      this.detailActive = false
      this.detailTermActive = false
      this.operationActive = false
      this.mode = true
      this.modeDisplay = 'Food'
      this.carbonActive = false
      this.select_state1 = 'Please select'
      this.select_state2 = 'Please select'
      this.select_state3 = 'Please select'
      this.productCardActivate = false
      this.imageGalleryActivate = false
      this.node_selected = false
      this.$store.state.current_graph_data = {}
      graphInstance.read(this.$store.state.current_graph_data)
    },
    initG6 (w, h, d) {
      const data = d
      // const elementwidth = this.$refs.infoBox.clientWidth
      // const elementheight = this.$refs.infobox.clientHeight
      graphInstance = new G6.Graph({
        container: 'graph',
        fitView: true,
        width: w,
        height: h,
        defaultNode: {
          // fill: '#40a9ff',
          // stroke: '#096dd9'
        },
        defaultEdge: {
          type: 'line',
          style: {
            endArrow: {
              path: G6.Arrow.triangle(10, 10),
              fill: '#413960',
              lineWidth: 3
            },
            stroke: '#413960',
            lineWidth: 1
          }
        },
        layout: {
          type: 'force',
          // type: 'gForce',
          // 防止重疊
          preventOverlap: true,
          linkDistance: 550
        },
        modes: {
          default: ['drag-canvas', 'zoom-canvas', 'drag-node'] // 允许拖拽画布、放缩画布、拖拽节点
        }
      })
      // graph.read(data)
      // graph.on('node:dragstart', function (e) {
      //   graph.layout()
      //   refreshDragedNodePosition(e)
      // })
      // graph.on('node:drag', function (e) {
      //   refreshDragedNodePosition(e)
      // })
      // graph.on('node:dragend', function (e) {
      //   e.item.get('model').fx = null
      //   e.item.get('model').fy = null
      // })
      // function refreshDragedNodePosition (e) {
      //   const model = e.item.get('model')
      //   model.fx = e.x
      //   model.fy = e.y
      // }
      graphInstance.read(data)
      graphInstance.on('node:dragstart', function (e) {
        graphInstance.layout()
        refreshDragedNodePosition(e)
      })
      graphInstance.on('node:drag', function (e) {
        refreshDragedNodePosition(e)
      })
      graphInstance.on('node:dragend', function (e) {
        e.item.get('model').fx = null
        e.item.get('model').fy = null
      })
      // graphInstance.on('node:click', ev => {
      //   const nodeID = ev.item.id
      //   // console.log(nodeID)
      //   this.showSomething()
      // })
      // 有時間的話會完成，選取他會highlight node並顯示node name
      // 另外會data紀錄上一個選取的node，選取到另一個就要取消掉上個style
      graphInstance.on('node:click', ev => {
        // some operations
        const nodeInstance = ev.item
        this.nodeName = nodeInstance._cfg.id
        this.node_selected = true
        this.showDetail(this.nodeName)
        this.showOperation(this.select_state2, this.nodeName)
      })
      // 針對手機平板的點擊事件，否則click對於手機平板是無效的，這個是指點完離開那瞬間
      graphInstance.on('node:touchend', ev => {
        // some operations
        const nodeInstance = ev.item
        this.nodeName = nodeInstance._cfg.id
        this.node_selected = true
        this.showDetail(this.nodeName)
        this.showOperation(this.select_state2, this.nodeName)
      })

      function refreshDragedNodePosition (e) {
        const model = e.item.get('model')
        model.fx = e.x
        model.fy = e.y
      }
    }
  }
}
</script>

<style scoped>
.toggle {
  color: #000000;
  font-size: 35px;
}

.custom-h-inside {
  height: 1200px;
}
/* #myWindow與#myContent是給秀圖套件用的*/
#myWindow {
  display: flex;
  /*align-items: center;*/
  /*justify-content: center;*/
  overflow: hidden;
}
#myContent {
  position: relative;
  display: flex;
  /*align-items: center;*/
}
</style>

<template>
  <nav class="flex justify-center text-3xl">
    <ul class="flex text-o_purple-200 font-semibold items-end capitalize mr-4  list-none">

      <li class="rounded-sm  py-2 mr-6 hover:underline" v-for="item in nav_items" :key="item.name">
        <router-link :to="{name: item.path}">
          {{ item.name }}
        </router-link>
      </li>
<!--      <a class="rounded-sm  py-2 mr-5 hover:underline"  target="_blank" href="https://www.for-paper-calendar.binyi.fun/">-->
<!--        Calendar-->
<!--      </a>-->
    </ul>
  </nav>

</template>

<script>
export default {
  name: 'NavBar',
  data: function () {
    return {
      nav_items: [
        {
          path: 'About',
          name: 'about'
        },
        // {
        //   path: 'Search',
        //   name: 'search'
        // },
        {
          path: 'Platform',
          name: 'platform'
        },
        {
          path: 'Terms',
          name: 'terms'
        },
        {
          path: 'Metaverse',
          name: 'metaverse'
        },
        {
          path: '3DRecords',
          name: '3DRecords'
        },
        {
          path: 'Records',
          name: 'records'
        }//,
        // {
        //   path: 'Documentation',
        //   name: 'documentation'
        // }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  //li:hover {
  //  list-style: disc;
  //}
</style>

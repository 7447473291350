<template>
  <div>
    <!-- -------------以下為選單選項區塊--------------------------   -->
    <div class="sticky top-0 bg-gray-50 border-b-4 pb-1.5 z-50">
      <div class="flex pt-2">
        <div class="flex flex-row items-center ml-4">
          <label id="listbox-label1" class="block text-3xl text-o_purple-100 font-semibold mr-2">
            Farm
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="isActive1 = !isActive1" type="button"
                      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
                {{ select_state1 }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>

            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="isActive1" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a @click="selectEvent"
                   v-for="f_option in farms" :key="f_option"
                   href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ f_option }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="searchBar2" class="flex flex-row items-center ml-4">
          <label id="listbox-label2" class="block text-3xl font-semibold text-o_purple-100 mr-2">
            Products
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="isActive2 = !isActive2" type="button"
                      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm
                      px-4 py-2 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100"
                      id="options-menu2" aria-haspopup="true" aria-expanded="true">
                {{ select_state2 }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>

            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="isActive2" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-0">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a @click="selectEvent2"
                   v-for="p_option in products" :key="p_option"
                   href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900 z-0" role="menuitem">
                  {{ p_option }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -------------以下為顯示內容區塊--------------------------   -->
<!--    <equipment-warehouse ref="equipRef"></equipment-warehouse>-->
    <virtual-records ref="equipRef"></virtual-records>
  </div>
</template>

<script>
// import EquipmentWarehouse from '@/components/EquipmentWarehouse'
import VirtualRecords from '@/components/VirtualRecords'
import Axios from 'axios'
import Config from '../config/config'
export default {
  name: 'VirtualRecord',
  components: { VirtualRecords },
  data: function () {
    return {
      farms: [],
      products: [],
      // assetActive: false,
      // sceneActive: false,
      assetInfoActive: false,
      virtualSceneActive: false,
      recordInfoActive: false,
      digitalSceneActive: false,
      select_state1: 'Please select',
      select_state2: 'Please select',
      searchBar2: false,
      searchBar3: false,
      isActive1: false,
      isActive2: false,
      hasAssets: {
        '2020-C1_Lu-Ming Rice_Tainan 16': ''
      },
      farmers: {
        '2020-C1_Lu-Ming Rice_Tian-Shang Chang': {
          id: '2020-C1_Lu-Ming Rice_Tainan 16 #1',
          graph_url: 'https://www.for-paper-static.binyi.fun/statics/graph_snapshot_2020-C1_Lu-Ming Rice_Tian-Shang Chang.png',
          url: 'https://www.akaswap.com/akaobj/3682'
        },
        '2020-C1_Lu-Ming Rice_Jin-Shi Hsieh': {
          id: '2020-C1_Lu-Ming Rice_Tainan 16 #2',
          graph_url: 'https://www.for-paper-static.binyi.fun/statics/graph_snapshot_2020-C1_Lu-Ming Rice_Jin-Shi Hsieh.png',
          url: 'https://www.akaswap.com/akaobj/3684'
        },
        '2020-C1_Lu-Ming Rice_Cheng-Po Hsu': {
          id: '2020-C1_Lu-Ming Rice_Tainan 16 #3',
          graph_url: 'https://www.for-paper-static.binyi.fun/statics/graph_snapshot_2020-C1_Lu-Ming Rice_Cheng-Po Hsu.png',
          url: 'https://www.akaswap.com/akaobj/3686'
        },
        '2020-C1_Lu-Ming Rice_Jin-Nan Hsieh': {
          id: '2020-C1_Lu-Ming Rice_Tainan 16 #4',
          graph_url: 'https://www.for-paper-static.binyi.fun/statics/graph_snapshot_2020-C1_Lu-Ming Rice_Jin-Nan Hsieh.png',
          url: 'https://www.akaswap.com/akaobj/3688'
        }
      }
    }
  },
  computed: {
    assetInfoActiveS: () => {
      return this.assetInfoActive
    },
    virtualSceneActiveS: () => {
      return this.virtualSceneActive
    },
    recordInfoActiveS: () => {
      return this.recordInfoActive
    }
  },
  created: function () {
    this.farms = this.$store.state.farmlist
    this.node_infos = this.$store.state.nodeInfo
  },
  mounted: function () {
    // 取得scene DOM element所在之處，利用id取得
    // const myVirtualSceneCanvas = document.getElementById('myVirtualSceneCanvas')
    // console.log('show myVirtualSceneCanvas')
    // console.log(myVirtualSceneCanvas)
    // // 建立scene instance
    // const scene = new THREE.Scene()
    // console.log('show scene')
    // console.log(scene)
    // // --------------------render設置渲染區域、建立尺寸、背景顏色、與顯示品質-------------------------------------------------------
    // // renderer可以渲染scene到指定的DOM元素上，以WebGLRenderer作為renderer
    // // renderer可以去設定: width與height(利用setSize) 、background color(利用setClearColor)
    // // 利用devicePixelRatio to improve the quality of the image (可以調整輸出的image品質，會去配合螢幕的物理像素分辨率來設置)
    // const renderer = new THREE.WebGLRenderer({ canvas: myVirtualSceneCanvas })
    // // 動態抓取element寬高的function
    // const width = this.$refs.myVirtualSceneCanvasRef.clientWidth
    // const height = this.$refs.myVirtualSceneCanvasRef.clientHeight
    // renderer.setSize(width, height)
    // renderer.setPixelRatio(window.devicePixelRatio)
    // console.log('show renderer')
    // console.log(renderer)
    // // color代號是從0x後開始，有些會寫成#號，ff3300是橘紅色
    // renderer.setClearColor(0xbfe3dd)
    // // 霧氣模糊效果
    // // scene.fog = new THREE.Fog( 0xa0a0a0, 10, 50 );
    // renderer.setPixelRatio(window.devicePixelRatio)
    // // --------------------添加perspective camera視角相機(呈現要的畫面角度、位置)-------------------------------------------------
    // // fov參數: 相機的垂直視野，超過視野不會被渲染
    // // aspect參數: 視野外觀比裡，一般width/height
    // // near參數: 相機近平面，若比此平面更近則不渲染
    // // far參數: 相機遠平面，若比此平面更遠則不渲染 (near、far兩平面建構一個最進、最遠的能渲染空間範圍)
    //
    // const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
    // // THREE object instance都可以下列方式來設置位置
    // camera.position.set(10, 5, 10)
    // console.log('show camera')
    // console.log(camera)
    // // -------------------相機旋轉縮放控制器設置(目前似乎有問題因為沒使用到，可能要查看一下參考資料)----------------------------------
    // // 這稱作軌道控制器，可以控制camera的移動於orbit軌道上(會環繞在一個sphere)，然後scroll mouse can zoom in or out
    // // 為額外的lib需要引入: import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
    // // Orbitcontrol object instance上有許多細節可以設置ref: https://medium.com/%E5%B0%8F%E5%BD%A5%E5%BD%A5%E7%9A%84%E5%89%8D%E7%AB%AF%E4%BA%94%E5%9B%9B%E4%B8%89/threejs-%E5%88%9D%E5%BF%83%E8%80%85%E5%85%A5%E9%96%80-%E4%B8%83-%E6%8E%A7%E5%88%B6%E5%99%A8-orbitcontrols-7b8d7c74a174
    // // 應該有像是只能轉到多少角度於水平 or 垂直，以及縮放的範圍，跟是否開放縮放、旋轉，也有自動旋轉...等細節，上述ref，也可以網路用關鍵字搜尋
    // // 下面是最基本的設置方式
    // const orbit = new OrbitControls(camera, renderer.domElement)
    // // 下面那條是硬加的，因為編譯eslint要求variable一定要被使用的風格，只好硬去設定enable控制器(他default本身就是true)
    // // 不過也可以發現可以在某些情況做鎖住，譬如跳窗出來的時候或許就能做類似操作
    // orbit.enabled = true
    // // --------------------GUI操作介面---------------------------------------------------------------------------------------
    // // 只設置width為300(其實這在介面中很常見，因為高無限)，可以建立一個panel儀錶板，並增添buttons給他做操作
    // // 我之後可能會將button對應的function改用click物件來觸發該functions，不過先用這個來針對目標觸發期望動畫
    // const gui = new GUI({ width: 300, autoPlace: false })
    // const customContainer = document.getElementById('myGUI')
    // customContainer.append(gui.domElement)
    // console.log('set GUI')
    // console.log(gui)
    // // --------------------GUI新增動作Action相關操作---------------------------------------------------------------------------
    // // actions為一個放置不同many different actions to this empty object named 'actions'
    // // 像是教學的例子有4個actions，TPose、Idle閒置、Walk走路、Sit坐著(也就是說可以有多個靜態、動態的動作，並可以去做切換)
    // const actions = {}
    // // 這也是常見的temp variable，會去紀錄選擇，這邊是紀錄當前選擇的selected actions
    // // 之後可以利用這種actions的temp去做切換，做一個動作的循環到結束
    // // eslint-disable-next-line camelcase
    // let current_action
    // // 這個object有一個"pause - resume" property，對應一個function
    // // 該function會去pause暫停或resume恢復某個current_action中存放的action
    // // 假如是我們的專案之後可能會有增加更多的property對應不同的models of different equipments
    // // eslint-disable-next-line camelcase
    // const pause_resume = {
    //   'pause - resume': () => {
    //     // 等於這是一個暫停/恢復函數，只要current_action當前有選中一個action，則此function會依據此
    //     // 會進行一個互斥的切換，初始應該是false(空值)，所以第一次被觸發會將pause設置成true
    //     // 這個current_action設置成object很聰明，因為除了記錄動作，也有是否運作中的狀態(If it is paused與否)
    //     // eslint-disable-next-line camelcase
    //     if (current_action) {
    //       current_action.paused = !current_action.paused
    //     }
    //   }
    // }
    // // 於GUI中產生一個按鈕並對應一個function
    // // add把pause_resume中存放的pause - resume的func添加到gui之中，並產生一個button對應此function
    // console.log('gui.add')
    // gui.add(pause_resume['pause - resume'])
    // console.log(gui)
    //
    // // 這可於gui panel上產生一個scroll options下拉選單，會顯示該下拉式選單名稱，叫做Actions，尚未設置options
    // // 之後應該是可以添加actions到裡面去，會影響current_action
    // // 我預期點立牌會有該equipment對應的operation的說明，而這個option則是選equipment，有另外button可以觸發他移動
    // const folder = gui.addFolder('Actions')
    // console.log('folder set')
    // // GLTFLoader instance會負責load models with their actions之後會置入insert to scene
    // const loader = new GLTFLoader()
    // console.log('loader set')
    // // animate the selected action動畫動作的一個necessary parameter(可能跟時間有一些關係，或許是運作的速率、長度...)
    // const clock = new THREE.Clock()
    // console.log('clock set')
    // // 作為identity and control actions識別、控制動作，之後會配合THREE的AnimationMixer動畫混合器，他的參數為動畫root物件
    // // 用於儲存所有動畫的數據(因此被說來用作identity and control actions)，可以混合多組動畫actions
    // // 而他其中並沒有什麼properties語methods，會透過一個叫做AnimationAction來針對Mixer內容進行操作(撥放、暫停或終止某個AnimationClip動畫片段
    // // ref: https://ithelp.ithome.com.tw/articles/10252394
    // let mixer
    // // mixer相關說明---------------------------------------------------------------------------------------------------------
    // // 入上他是作為animation action操作用途，會用來承接從scene取出的actions，透過new THREE.AnimationMixer(glb.scene)此instance
    // // 譬如他提供動畫停止的func: stopAllAction，這很適合作為動畫間的切換，使得動畫之間不會衝突
    //
    // loader.load(
    //   // 用相對位置指出.glb檔案所在位置
    //   // eslint-disable-next-line import/no-absolute-path
    //   // 一般vueJS的static resources會放在public之中，然後可以用下面path方式直接獲取到，就直接dir/path/fileName
    //   '3dModels/warehouse_scene_tools_compressed_v2_light_cutAction.glb',
    //   (glb) => {
    //     mixer = new THREE.AnimationMixer(glb.scene)
    //     console.log(mixer)
    //     // forLoop會exhaust all the actions inside the scene
    //     // value會有name(就是在blender中action的name)
    //     console.log('animations')
    //     console.log(glb.animations)
    //     for (const value of glb.animations) {
    //       const action = mixer.clipAction(value)
    //       // 每個action的name都給他對應一個function，這邊是做一個general
    //       // 之後應該可以再開一個object可能作為其他用途，或者針對某些action
    //       actions[value.name] = () => {
    //         // 首先停止其他action ("之後要確定停止是否位置也會停住
    //         // mixer.stopAllAction()
    //
    //         // 下面這個是互斥設計，若想要可以同時則可以disable
    //         // if (current_action) {
    //         //   console.log(current_action)
    //         //   current_action.paused = true
    //         //   action.paused = false
    //         // }
    //
    //         // 下面這條註銷掉的話似乎會一直重複循環
    //         // action.setLoop(THREE.LoopOnce);
    //         action.clampWhenFinished = true
    //         action.loop = THREE.LoopOnce
    //         // 播放該action ("可能要做雙向action at path，各自獨立名稱")
    //         action.play()
    //         // ref https://www.codeleading.com/article/21441734023/
    //         // actions.forEach(function (obj) {
    //         //   if (obj == action) {
    //         //     obj.play()
    //         //   } else {
    //         //     obj.stop()
    //         //   }
    //         // })
    //         // 當前運作action變成此action
    //         // eslint-disable-next-line camelcase
    //         current_action = action
    //       }
    //       // folder是gui編輯用的，gui上的options透過輸入json object list以及，名稱
    //       // 他會要求一個function的map list(可以每次新增新的action name就再更新
    //
    //       // 下面這個是reset功能，reset and stop
    //       folder.add(actions, value.name)
    //
    //       actions['reset' + '_' + value.name] = () => {
    //         console.log('Helloooooooooo~')
    //         action.reset()
    //         action.stop()
    //         // action.paused = false
    //       }
    //       folder.add(actions, 'reset' + '_' + value.name)
    //     }
    //     actions['combine' + '_' + 'harvesterAndTractor'] = () => {
    //       console.log(glb.animations)
    //       console.log('Helloooooooooo~ tractor and harvester')
    //       const Action1 = mixer.clipAction(glb.animations[0])
    //       const Action2 = mixer.clipAction(glb.animations[1])
    //       Action1.clampWhenFinished = true
    //       Action1.loop = THREE.LoopOnce
    //       Action2.clampWhenFinished = true
    //       Action2.loop = THREE.LoopOnce
    //       Action1.play()
    //       Action2.play()
    //       // eslint-disable-next-line camelcase
    //       current_action = Action1
    //     }
    //     folder.add(actions, 'combine' + '_' + 'harvesterAndTractor')
    //     document.getElementById('harvesterAndTractorOut').onclick = actions.combine_harvesterAndTractor
    //     // 實際添加場景進來
    //     scene.add(glb.scene)
    //     animate()
    //   }
    // )
    // console.log('show loader')
    // console.log(loader)
    // actions.reset = () => {
    //   console.log('Helloooooooooo~')
    // }
    // const animate = function () {
    //   requestAnimationFrame(animate)
    //   mixer.update(clock.getDelta())
    //   renderer.render(scene, camera)
    // }
  },
  methods: {
    // 取得選單內容from database
    async GetMenuList () {
      const menuListRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await menuListRequest.get('/menu/list').then(res => {
        this.select_state1 = 'Rice House'
        this.isActive1 = !this.isActive1
        this.productList = res.data
        const root = [Object.keys(this.productList)]
        let productList = []
        productList = productList.concat(...root)
        this.products = productList
      })
    },
    selectEvent () {
      this.GetMenuList()
      // this.assetActive = false
      // this.sceneActive = false
      this.$refs.equipRef.recordInfoActive = false
      this.$refs.equipRef.recordPanel = false
      this.$refs.equipRef.currentOperationType = ''
      this.$refs.equipRef.currentAnimationRecordURL = ''
      this.$refs.equipRef.videoActive = false
      this.searchBar2 = true
      this.select_state2 = 'Please select'
    },
    async selectEvent2 (event) {
      this.$refs.equipRef.isLoading = true
      await this.$refs.equipRef.GetRecordContentWithoutAuthByProductAndOperations(event.target.innerText)
      // this.operationCarbonActive = false
      // this.searchBar3 = true
      this.select_state2 = event.target.innerText
      this.isActive2 = !this.isActive2
      // this.$refs.equipRef.recordInfoActive = false
      console.log(typeof this.farmers)
      console.log('checkpoint 01')
      console.log(Object.prototype.hasOwnProperty.call(this.hasAssets, this.select_state2))
      if (Object.prototype.hasOwnProperty.call(this.hasAssets, this.select_state2)) {
        this.$refs.equipRef.recordInfoActive = true
      } else {
        this.$refs.equipRef.recordInfoActive = false
      }
      this.$refs.equipRef.isLoading = false
      // this.GetMenuList2(this.select_state2)
    }
  }
}
</script>

<style scoped>

</style>

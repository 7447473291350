<template>
  <div class="">
    <!--------------以下為選單選項區塊---------------------------->
    <div class="sticky top-0 bg-gray-50 border-b-4 pb-1.5 z-50">
      <div class="flex pt-2">
        <div class="flex flex-row items-center ml-4">
          <label id="listbox-label1" class="block text-3xl text-o_purple-100 font-semibold mr-2">
            Farm
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="isActive1 = !isActive1" type="button"
                      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
                {{ select_state1 }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>

            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="isActive1" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a @click="selectEvent"
                   v-for="f_option in farms" :key="f_option"
                   href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ f_option }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="searchBar2" class="flex flex-row items-center ml-4">
          <label id="listbox-label2" class="block text-3xl font-semibold text-o_purple-100 mr-2">
            Products
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="isActive2 = !isActive2" type="button"
                      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm
                      px-4 py-2 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100"
                      id="options-menu2" aria-haspopup="true" aria-expanded="true">
                {{ select_state2 }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>

            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="isActive2" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a @click="selectEvent2"
                   v-for="p_option in products" :key="p_option"
                   href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ p_option }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--------------以下為Loading---------------------------->
    <div v-show="isLoading" wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 class="text-center text-white text-4xl font-semibold">Loading Data ...</h2>
      <p class="w-1/3 text-center text-white text-2xl">It will take some time, please do not close this page or do other things</p>
    </div>
    <!-- -------------以下為顯示內容區塊--------------------------   -->
    <div class="grid grid-cols-8 grid-rows-7 grid-flow-col gap-1.5 mt-5 custom-h-inside">
      <div class="rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-3 row-span-7 overflow-auto p-6">
        <!-- 詞彙說明與按鈕 -->
        <!-- 下列為測試內容 -->
<!--        <ul class="text-o_purple-100 text-3xl my-3">-->
<!--          <li class="text-o_purple-100 text-3xl mb-4  mt-1 pt-4 border-b-4 border-t_dark_brown">-->
<!--            <button type="button"-->
<!--                    class=" font-semibold mb-4-->
<!--              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md-->
<!--              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2-->
<!--              focus:ring-offset-2 focus:ring-o_purple-100">-->
<!--              2020-C1_Lu-Ming Rice_Tian-Shang Chang_Pesticide And Detecting ....................-->
<!--            </button><br>-->
<!--            <span class="font-semibold">各國翻譯(中/英/日):</span><br>-->
<!--            <div class="pb-3.5">Pesticide And Detecting/ 農藥快篩/ 残留農薬の簡易迅速スクリーニング</div>-->
<!--            <span class="font-semibold">內容:</span><br>-->
<!--            <div class="pb-3.5">Post harvest: Pesticide residue test.</div>-->
<!--          </li>-->
<!--        </ul>-->
        <!-- 下為實際內容 -->
        <ul v-show="true" class="text-o_purple-100 text-3xl my-1">
          <li v-for="(nodeInfo, i) in nodeInfoList"  :key="i" class="text-o_purple-100 text-3xl mb-4  mt-0.5 pt-0.5 border-b-4 border-t_dark_brown">
            <button @click="clickNodeEvent(nodeInfo.name)"
                    v-if="true"
                    type="button"
                    class=" font-semibold mb-4
              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
              {{nodeInfo.name}}
            </button><br v-show="nodeInfo.isTerm">
            <span v-show="nodeInfo.isTerm" class="font-semibold">Terms in multi-languages(en/zh/ja):</span><br>
            <div class="pb-3.5" v-show="nodeInfo.isTerm">{{nodeInfo.eng}}/{{nodeInfo.zh}}/{{nodeInfo.jp}} </div>
            <span class="font-semibold">Content:</span><br>
            <div class="pb-3.5">{{ nodeInfo.content }}</div>
          </li>
        </ul>
      </div>
      <!-- 圖譜產生區域 -->
      <div id="graph" class="rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-5 row-span-full overflow-auto p-6" ref="canvas"></div>
    </div>

  </div>
</template>

<script>
import Axios from 'axios'
import G6 from '@antv/g6'
import Config from '../config/config'
let graphInstance = {}
export default {
  name: 'Terms',
  data: function () {
    return {
      farms: [],
      products: [],
      select_state1: 'Please select',
      select_state2: 'Please select',
      searchBar2: false,
      isActive1: false,
      isActive2: false,
      nodeInfoList: [],
      isLoading: false,
      current_graph: {}
    }
  },
  created: function () {
    this.farms = this.$store.state.farmlist
    this.node_infos = this.$store.state.nodeInfo
  },
  mounted () {
    // this.img_s_sources = this.$store.state.imagePathB
    this.$store.state.current_graph_data = {}
    // 我個人覺得我這邊用nextTick有點怪，因為mounted階段已經有DOM了
    this.$nextTick(function () {
      // 動態抓取element寬高的function
      const width = this.$refs.canvas.clientWidth
      const height = this.$refs.canvas.clientHeight
      this.initG6(width, height, this.$store.state.current_graph_data)
    })
  },
  methods: {
    // 取得選單內容from database
    async GetMenuList () {
      const menuListRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await menuListRequest.get('/menu/list').then(res => {
        this.select_state1 = 'Rice House'
        this.isActive1 = !this.isActive1
        this.productList = res.data
        const root = [Object.keys(this.productList)]
        let productList = []
        productList = productList.concat(...root)
        this.products = productList
      })
    },
    selectEvent () {
      this.GetMenuList()
      this.assetActive = false
      this.sceneActive = false
      this.searchBar2 = true
      this.select_state2 = 'Please select'
      this.nodeInfoList = []
      this.current_graph = {}
      graphInstance.read(this.current_graph)
    },
    async selectEvent2 (event) {
      // this.operationCarbonActive = false
      // this.searchBar3 = true
      this.select_state2 = event.target.innerText
      this.isActive2 = !this.isActive2
      // this.GetMenuList2(this.select_state2)
      await this.getNodeInfo()
      this.current_graph = {}
      graphInstance.read(this.current_graph)
    },
    async getNodeInfo () {
      this.nodeInfoList = []
      this.isLoading = true
      const dataForm = new FormData()
      dataForm.append('product', this.select_state2)
      const nodeInfoRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await nodeInfoRequest.post('/graph/nodes', dataForm).then(res => {
        const resTemp = res.data
        // console.log(resTemp)
        // 這個API回傳僅有一個result array因此錯誤為null反之則有內容，有內容就執行
        if (resTemp) {
          this.nodeInfoList = resTemp
          // console.log(this.nodeInfoList)
        }
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      // console.log(this.nodeInfoList)
      this.isLoading = false
    },
    async clickNodeEvent (nodeName) {
      await this.GetGraph(nodeName, 3, 3)
      graphInstance.read(this.current_graph)
    },
    async GetGraph (name, uLevel, dLevel) {
      const dataForm = new FormData()
      dataForm.append('name', name)
      dataForm.append('u_level', uLevel)
      dataForm.append('d_level', dLevel)
      const graphRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await graphRequest.post('/graph/graph', dataForm).then(res => {
        this.current_graph = { nodes: res.data.node_styles, edges: res.data.edges }
      })
    },
    initG6 (w, h, d) {
      const data = d
      // const elementwidth = this.$refs.infoBox.clientWidth
      // const elementheight = this.$refs.infobox.clientHeight
      graphInstance = new G6.Graph({
        container: 'graph',
        fitView: true,
        width: w,
        height: h,
        defaultNode: {
          // fill: '#40a9ff',
          // stroke: '#096dd9'
        },
        defaultEdge: {
          type: 'line',
          style: {
            endArrow: {
              path: G6.Arrow.triangle(10, 10),
              fill: '#413960',
              lineWidth: 3
            },
            stroke: '#413960',
            lineWidth: 1
          }
        },
        layout: {
          type: 'force',
          // type: 'gForce',
          // 防止重疊
          preventOverlap: true,
          linkDistance: 550
        },
        modes: {
          default: ['drag-canvas', 'zoom-canvas', 'drag-node'] // 允许拖拽画布、放缩画布、拖拽节点
        }
      })
      // graph.read(data)
      // graph.on('node:dragstart', function (e) {
      //   graph.layout()
      //   refreshDragedNodePosition(e)
      // })
      // graph.on('node:drag', function (e) {
      //   refreshDragedNodePosition(e)
      // })
      // graph.on('node:dragend', function (e) {
      //   e.item.get('model').fx = null
      //   e.item.get('model').fy = null
      // })
      // function refreshDragedNodePosition (e) {
      //   const model = e.item.get('model')
      //   model.fx = e.x
      //   model.fy = e.y
      // }
      graphInstance.read(data)
      graphInstance.on('node:dragstart', function (e) {
        graphInstance.layout()
        refreshDragedNodePosition(e)
      })
      graphInstance.on('node:drag', function (e) {
        refreshDragedNodePosition(e)
      })
      graphInstance.on('node:dragend', function (e) {
        e.item.get('model').fx = null
        e.item.get('model').fy = null
      })
      // graphInstance.on('node:click', ev => {
      //   const nodeID = ev.item.id
      //   // console.log(nodeID)
      //   this.showSomething()
      // })
      // 有時間的話會完成，選取他會highlight node並顯示node name
      // 另外會data紀錄上一個選取的node，選取到另一個就要取消掉上個style
      graphInstance.on('node:click', ev => {
        // some operations
        const nodeInstance = ev.item
        this.nodeName = nodeInstance._cfg.id
        this.node_selected = true
        this.showDetail(this.nodeName)
        this.showOperation(this.select_state2, this.nodeName)
      })

      function refreshDragedNodePosition (e) {
        const model = e.item.get('model')
        model.fx = e.x
        model.fy = e.y
      }
    }
  }
}
</script>

<style scoped>
.custom-h-inside {
  height: 1000px;
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)
// 之後要大整修，因為全部使用directly control state attrs，高風險崩潰
export default new Vuex.Store({
  state: {
    testState: '',
    // 圖片路徑、標題、解說(會繼續在前端，因為屬於statics)，不過量多後路徑、解說可能會挪到db
    imagePathS: [
      require('../assets/pr_tem/pt_small/05_new2017_pr_s.png'),
      require('../assets/pr_tem/pt_small/06_new2017_temp_s.png'),
      require('../assets/Erlin/small/01_Erlin_Irrigation_Workstation_s.png'),
      require('../assets/Erlin/small/02_Paddy_s.png'),
      require('../assets/Erlin/small/03_Paddy_Productivity_I_s.png'),
      require('../assets/Erlin/small/04_Paddy_Productivity_II_s.png'),
      require('../assets/Erlin/small/05_Farmland Classification_s.png')
    ],
    imagePathB: [
      require('../assets/pr_tem/pt_original/05_new2017_pr.png'),
      require('../assets/pr_tem/pt_original/06_new2017_temp.png'),
      require('../assets/Erlin/original/01_Erlin_Irrigation_Workstation.png'),
      require('../assets/Erlin/original/02_Paddy.png'),
      require('../assets/Erlin/original/03_Paddy_Productivity_I.png'),
      require('../assets/Erlin/original/04_Paddy_Productivity_II.png'),
      require('../assets/Erlin/original/05_Farmland Classification.png')
    ],
    imageInfo: [
      {
        title: '2017 Precipitation (mm)',
        detail: ''
      },
      {
        title: '2017 Temperature (°C)',
        detail: ''
      },
      {
        title: 'Irrigation Information',
        detail: 'The irrigation area of Erlin Irrigation Workstation'
      },
      {
        title: 'Environmental  Information',
        detail: 'The paddy distribution  of Erlin Irrigation Workstation.'
      },
      {
        title: 'Others Information',
        detail: 'The classification of first season crop productivity in Erlin Irrigation Workstation area. ' +
          'The productivity is classified on average yield (Kg/ha).'
      },
      {
        title: 'Others Information',
        detail: 'The classification of second season crop productivity in Erlin Irrigation Workstation area. ' +
          'The productivity is classified on average yield (Kg/ha).'
      },
      {
        title: 'Others Information',
        detail: 'The classification of farmland  in Erlin Irrigation Workstation area. ' +
          'The classification definition is based on the size of the land and the use purpose.'
      }
    ],
    // node info之後這部分會存放在資料庫，但這格式可以參考(點擊node才會有因此這個是屬於以單一名稱query的操作)
    nodeInfo: {
      '2020C1-Lu Ming Rice_Tian-Shang Chang': { content: 'Product & Farmer' },
      '2020C1-Lu Ming Rice_Jin-Shi Hsieh': { content: 'Product & Farmer' },
      '2020C1-Lu Ming Rice_Jin-Nan Hsieh': { content: 'Product & Farmer' },
      '2020C1-Lu Ming Rice_Cheng-Po Hsu': { content: 'Product & Farmer' },
      'Rice House': { content: 'Rice Mill' },
      'Cheng-Po Hsu\'s Organic Farm': { content: 'Farmer & Farm' },
      'Tian-Shang Chang\'s Organic Farm': { content: 'Farmer & Farm' },
      'Jin-Shi Hsieh\'s Organic Farm': { content: 'Farmer & Farm' },
      'Jin-Nan Hsieh\'s Organic Farm': { content: 'Farmer & Farm' },
      'Site Preparation': {
        content: 'Land preparation: \n' +
          'The main purpose of land preparation is to have the soil in optimum physical condition for growing rice.'
      },
      'Rice Seed Transplantation': {
        content: 'Crop establishment: \n' +
          'Transplanting occurs when pre-germinated seedlings are transferred from a seedbed to the wet field.'
      },
      Irrigation: {
        content: 'Water management: \n' +
          'Cultivated rice is extremely sensitive to water shortages and when the soil water content drops below saturation.'
      },
      Fertilization: {
        content: 'Nutrient management: \n' +
          'Applying nutrients to the crop is essential in managing soil fertility so the plants grow and develop normally.'
      },
      'Biotic Suppression Operation': {
        content: 'Crop health management: \n' +
          'Depending upon various factors, Pest control management is crucial.'
      },
      'Harvesting And Milling': {
        content: 'Harvesting & Post harvest: \n' +
          'Harvesting activities include cutting, stacking, handling, threshing, cleaning, and hauling.\n' +
          'Post harvest steps include drying, storing, milling, and final processing.'
      },
      'Pesticide And Detecting': {
        content: 'Post harvest: \n' +
          'Pesticide residue test.'
      },
      Delivering: {
        content: 'Post harvest: \n' +
          'Transports rice to their destination via vehicles which must be cleaned, sanitized, and ready to operate.'
      },
      Tractor: {
        content: 'Land preparation: \n' +
          'The proper preparation of land for sowing is achieved via tractors'
      },
      'Rice Transplanter': {
        content: 'Crop establishment: \n' +
          'Seedlings can be transplanted by rice tensplantor.'
      },
      Drainaging: {
        content: 'Water management: \n' +
          'artificial application of water from land.'
      },
      Irrigating: {
        content: 'Water management: \n' +
          'artificial application of water to land.'
      },
      'Fusow organic fertilizer 426': {
        content: 'Nutrient management: \n' +
          'At each growth stage, the rice plant has specific nutrient needs.'
      },
      'Fusow special fertilizer for organic cultivation 522': {
        content: 'Nutrient management: \n' +
          'At each growth stage, the rice plant has specific nutrient needs.'
      },
      'Fagopyrum Esculentum Seed': {
        content: 'Nutrient management: \n' +
          'At each growth stage, the rice plant has specific nutrient needs.'
      },
      'Ear fertilizer': {
        content: 'Nutrient management: \n' +
          'At each growth stage, the rice plant has specific nutrient needs.'
      },
      'Tea Seed Meal': {
        content: 'Crop health management: \n' +
          'Farmers manage Pest control through non-pesticide materials.'
      },
      'Paddy complete harvester': {
        content: 'Harvesting: \n' +
          'Harvesting can be done by harvester.'
      },
      'Low Temperature Bin': {
        content: 'Post harvest: \n' +
          'Low temperature storage conditions will ensure minimal loss of paddy rice due to ' +
          'changes in weather and moisture content, rodents, insects, microorganisms etc.'
      },
      'Low Temperature Paddy Dryer': {
        content: 'Post harvest: \n' +
          'Rice can be stored for longer periods if moisture content is maintained at less than 14%.'
      },
      'Brown Rice Milling + Package': {
        content: 'Post harvest: \n' +
          'Milling rice paddy removes the husk to produce brown rice.'
      },
      'Milled Rice+Package': {
        content: 'Post harvest: \n' +
          'Milling rice paddy removes the husk and bran layer to produce white rice.'
      },
      Pass: {
        content: 'Post harvest: \n' +
          'Products pass the pesticide residue test.'
      }
    },
    farmlist: [
      'Rice House'
    ],
    cardList: {
      'Rice House': [
        '2020C1-Lu Ming Rice_Tainan 16',
        '2020C1-Lu Ming Rice_Tian-Shang Chang',
        '2020C1-Lu Ming Rice_Jin-Shi Hsieh',
        '2020C1-Lu Ming Rice_Cheng-Po Hsu',
        '2020C1-Lu Ming Rice_Jin-Nan Hsieh'
      ]
    },
    // 這是選到該產品graph會秀出來的，包含他的blockchain位址，之後區塊鏈也會設計成能以產品批號名稱搜索相關操作交易的smart contract
    // 等於算是product info，會有某季總以及其下各自不同農夫(其實格式不用太在意，json很容易更改
    productCardInfo: {
      '2020-C1_Lu-Ming Rice_Tainan 16': [{
        Farm: 'Rice House',
        City: 'Changhua County',
        Town: 'Erlin Town',
        Address: 'No. 2-3. Rulin Rd.,Nanguang Vil.',
        Phone: '04 896 5111'
      }, 'http://140.112.76.152:4000/address/0x1718253eeF065C14d2640025a4F5920c6e47B93B/transactions'],
      '2020-C1_Lu-Ming Rice_Tian-Shang Chang': [{
        Farm: 'Rice House',
        City: 'Changhua County',
        Town: 'Erlin Town',
        Address: 'No. 2-3. Rulin Rd.,Nanguang Vil.',
        Phone: '04 896 5111'
      }, 'http://140.112.76.152:4000/address/0xacC6aDb7D8a035728A73787ddEe3cfbFdeFe46Bd/transactions'],
      '2020-C1_Lu-Ming Rice_Cheng-Po Hsu': [{
        Farm: 'Rice House',
        City: 'Changhua County',
        Town: 'Erlin Town',
        Address: 'No. 2-3. Rulin Rd.,Nanguang Vil.',
        Phone: '04 896 5111'
      }, 'http://140.112.76.152:4000/address/0x56786Ee81b9Ffb4f7d589653e64295c10E6f2527/transactions'],
      '2020-C1_Lu-Ming Rice_Jin-Shi Hsieh': [{
        Farm: 'Rice House',
        City: 'Changhua County',
        Town: 'Erlin Town',
        Address: 'No. 2-3. Rulin Rd.,Nanguang Vil.',
        Phone: '04 896 5111'
      }, 'http://140.112.76.152:4000/address/0x6781Ef28252A3A9e15A8bFe44C4558D7ab1C767D/transactions'],
      '2020-C1_Lu-Ming Rice_Jin-Nan Hsieh': [{
        Farm: 'Rice House',
        City: 'Changhua County',
        Town: 'Erlin Town',
        Address: 'No. 2-3. Rulin Rd.,Nanguang Vil.',
        Phone: '04 896 5111'
      }, 'http://140.112.76.152:4000/address/0x08C6D4122955d1471AaD762c71b96EB15Dae50ef/transactions']
    },
    // 下面這個事之前設計的format for each operation，目前沒有使用
    product_operations: {
      LMR: {
        LMR_TSC: {
          '2020-08-19 16:19:33': {
            'Crop code': '2020-1-Lu Ming Rice_Tian-Shang Chang',
            Event: 'Irrigation',
            'Operation place': 'Tian-Shang Chang\'s Organic Farm',
            'Irrigation/drainage': 'Irrigation',
            Note: ''
          },
          '2020-06-03 16:45:34': {
            'Crop code': '2020-1-Lu Ming Rice_Tian-Shang Chang',
            Event: 'Harvesting and milling',
            'Operation place': 'Rice House',
            'Store place': 'Low temperature bin',
            Note: 'Low temperature storage'
          },
          '2020-06-02 15:35:38': {
            'Crop code': '2020-1-Lu Ming Rice_Tian-Shang Chang',
            Event: 'Harvesting and milling',
            'Operation place': 'Rice House',
            Equipment: 'Low temperature paddy dryer',
            Note: 'Paddy Drying'
          },
          '2020-06-02 14:45:31': {
            'Crop code': '2020-1-Lu Ming Rice_Tian-Shang Chang',
            Event: 'Harvesting and milling',
            'Operation place': 'Rice House',
            Equipment: 'Low temperature paddy dryer',
            Note: 'Paddy Drying'
          },
          '2020-06-02 13:18:58': {
            'Crop code': '2020-1-Lu Ming Rice_Tian-Shang Chang',
            Event: 'Harvesting and milling',
            'Operation place': 'Rice House',
            Equipment: 'Low temperature paddy dryer',
            Note: 'Paddy Drying'
          },
          '2020-05-31 13:16:36': {
            'Crop code': '2020-1-Lu Ming Rice_Tian-Shang Chang',
            Event: 'Pesticide rapid detecting',
            'Operation place': 'Rice House',
            Results: 'Pass',
            Note: ''
          }
        },
        LMR_JSH: {
          '2020-08-19 16:19:33': {
            'Crop code': '2020-1-Lu Ming Rice_Tian-Shang Chang',
            Event: 'Irrigation',
            'Operation place': 'Tian-Shang Chang\'s Organic Farm',
            'Irrigation/drainage': 'Irrigation',
            Note: ''
          },
          '2020-06-03 16:45:34': {
            'Crop code': '2020-1-Lu Ming Rice_Tian-Shang Chang',
            Event: 'Harvesting and milling',
            'Operation place': 'Rice House',
            'Store place': 'Low temperature bin',
            Note: 'Low temperature storage'
          },
          '2020-06-02 15:35:38': {
            'Crop code': '2020-1-Lu Ming Rice_Tian-Shang Chang',
            Event: 'Harvesting and milling',
            'Operation place': 'Rice House',
            Equipment: 'Low temperature paddy dryer',
            Note: 'Paddy Drying'
          },
          '2020-06-02 14:45:31': {
            'Crop code': '2020-1-Lu Ming Rice_Tian-Shang Chang',
            Event: 'Harvesting and milling',
            'Operation place': 'Rice House',
            Equipment: 'Low temperature paddy dryer',
            Note: 'Paddy Drying'
          },
          '2020-06-02 13:18:58': {
            'Crop code': '2020-1-Lu Ming Rice_Tian-Shang Chang',
            Event: 'Harvesting and milling',
            'Operation place': 'Rice House',
            Equipment: 'Low temperature paddy dryer',
            Note: 'Paddy Drying'
          },
          '2020-05-31 13:16:36': {
            'Crop code': '2020-1-Lu Ming Rice_Tian-Shang Chang',
            Event: 'Pesticide rapid detecting',
            'Operation place': 'Rice House',
            Results: 'Pass',
            Note: ''
          }

        },
        LMR_CPH: {
          '2020-08-24 13:23:39': {
            'Crop code': '2020-1-Lu Ming Rice_Cheng-Po Hsu',
            Event: 'Irrigation',
            'Operation place': 'Cheng-Po Hsu\'s Organic Farm',
            'Irrigation/drainage': 'Irrigation',
            Note: ''
          },
          '2020-06-05 07:52:06': {
            'Crop code': '2020-1-Lu Ming Rice_Cheng-Po Hsu',
            Event: 'Harvesting and milling',
            'Operation place': 'Cheng-Po Hsu\'s Organic Farm',
            'Harvest Approach': 'Paddy complete harvester',
            Note: 'Harvest'
          },
          '2020-06-03 16:44:57': {
            'Crop code': '2020C1-Lu Ming Rice_Cheng-Po Hsu',
            Event: 'Harvesting and milling',
            'Operation place': 'Rice House',
            'Store place': 'Low temperature bin',
            Note: 'Low temperature storage'
          },
          '2020-06-02 17:35:30': {
            'Crop code': '2020C1-Lu Ming Rice_Cheng-Po Hsu',
            Event: 'Harvesting and milling',
            'Operation place': 'Rice House',
            Equipment: 'Low temperature paddy dryer',
            Note: 'Paddy Drying'
          },
          '2020-05-31 13:14:46': {
            'Crop code': '2020C1-Lu Ming Rice_Cheng-Po Hsu',
            Event: 'Pesticide rapid detecting',
            'Operation place': 'Rice House',
            Results: 'Pass',
            Note: ''
          },
          '2020-05-04 19:42:38': {
            'Crop code': '2020-1-Lu Ming Rice_Cheng-Po Hsu',
            Event: 'Irrigation',
            'Operation place': 'Cheng-Po Hsu\'s Organic Farm',
            'Irrigation/drainage': 'Irrigation',
            Note: ''
          }
        }
      }
    },
    //  目前設計是思考說，初始就是root big graph，點選切換才換到該root下的sub-graph
    //  點選單中就會送出該節點往下到底，並且往上變0，但若是進到結點選擇後，則可以往上顯示隱藏的parent nodes
    graphs: {
      '2020C1-Lu Ming Rice_Tainan 16': {
        nodes: [
          // 可以利用 \n  換行
          {
            id: '2020C1-Lu Ming Rice_Tian-Shang Chang',
            groupId: 'farmer',
            // visible: false,
            size: 170,
            label: '2020C1-\nLu Ming Rice_\nTian-Shang Chang',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#fe917d' }
          },
          {
            id: '2020C1-Lu Ming Rice_Jin-Shi Hsieh',
            groupId: 'farmer',
            size: 170,
            label: '2020C1-\nLu Ming Rice_\nJin-Shi Hsieh',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#fe917d' }
          },
          {
            id: '2020C1-Lu Ming Rice_Cheng-Po Hsu',
            groupId: 'farmer',
            size: 170,
            label: '2020C1-\nLu Ming Rice_\nCheng-Po Hsu',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#fe917d' }
          },
          {
            id: '2020C1-Lu Ming Rice_Jin-Nan Hsieh',
            groupId: 'farmer',
            size: 150,
            label: '2020C1-\nLu Ming Rice_\nJin-Nan Hsieh',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#fe917d' }
          }, // operations1
          {
            id: 'Rice Seed Transplantation',
            size: 170,
            label: 'Rice Seed\n Transplantation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Site Preparation',
            size: 170,
            label: 'Site Preparation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Irrigation',
            size: 170,
            label: 'Irrigation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Fertilization',
            size: 170,
            label: 'Fertilization',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Biotic Suppression Operation',
            size: 170,
            label: 'Biotic\n Suppression Operation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          }, // operation2
          {
            id: 'Harvesting And Milling',
            size: 170,
            label: 'Harvesting\n And Milling',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Delivering',
            size: 170,
            label: 'Delivering',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Pesticide And Detecting',
            size: 170,
            label: 'Pesticide\n And Detecting',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Rice House',
            size: 170,
            label: 'Rice House',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#ffffff' }
          },
          {
            id: 'Tian-Shang Chang\'s Organic Farm',
            size: 170,
            label: 'Tian-Shang Chang\'s\n Organic Farm',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#ffffff' }
          },
          {
            id: 'Jin-Shi Hsieh\'s Organic Farm',
            size: 170,
            label: 'Jin-Shi Hsieh\'s\n Organic Farm',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#ffffff' }
          },
          {
            id: 'Cheng-Po Hsu\'s Organic Farm',
            size: 170,
            label: 'Cheng-Po Hsu\'s\n Organic Farm',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#ffffff' }
          },
          {
            id: 'Jin-Nan Hsieh\'s Organic Farm',
            size: 170,
            label: 'Jin-Nan Hsieh\'s\n Organic Farm',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#ffffff' }
          },
          {
            id: 'Rice Transplanter',
            size: 170,
            label: 'Rice \nTransplanter',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Tractor',
            size: 170,
            label: 'Tractor',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Irrigating',
            size: 170,
            label: 'Irrigating',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Drainaging',
            size: 170,
            label: 'Drainaging',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fusow organic fertilizer 426',
            size: 170,
            label: 'Fusow organic\n fertilizer 426',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Ear fertilizer',
            size: 170,
            label: 'Ear fertilizer',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fusow special fertilizer for organic cultivation 522',
            size: 170,
            label: 'Fusow special\n fertilizer for\n organic cultivation\n 522',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fagopyrum Esculentum Seed',
            size: 170,
            label: 'Fagopyrum \nEsculentum Seed',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Tea Seed Meal',
            size: 170,
            label: 'Tea Seed Meal',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Brown Rice Milling + Package',
            size: 170,
            label: 'Brown Rice \nMilling + Package',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Milled Rice+Package',
            size: 170,
            label: 'Milled Rice\n+Package',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Low Temperature Paddy Dryer',
            size: 170,
            label: 'Low Temperature\n Paddy Dryer',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Low Temperature Bin',
            size: 170,
            label: 'Low Temperature\n Bin',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Paddy complete harvester',
            size: 170,
            label: 'Paddy complete\n harvester',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Pass',
            size: 170,
            label: 'Pass',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          }
        ],
        edges: [
          {
            source: '2020C1-Lu Ming Rice_Tian-Shang Chang',
            target: 'Rice Seed Transplantation'
          },
          {
            source: 'Rice Seed Transplantation',
            target: "Tian-Shang Chang's Organic Farm"
          },
          {
            source: '2020C1-Lu Ming Rice_Tian-Shang Chang',
            target: 'Site Preparation'
          },
          { source: 'Site Preparation', target: "Tian-Shang Chang's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Tian-Shang Chang', target: 'Irrigation' },
          { source: 'Irrigation', target: "Tian-Shang Chang's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Tian-Shang Chang', target: 'Fertilization' },
          { source: 'Fertilization', target: "Tian-Shang Chang's Organic Farm" },
          {
            source: '2020C1-Lu Ming Rice_Tian-Shang Chang',
            target: 'Biotic Suppression Operation'
          },
          {
            source: 'Biotic Suppression Operation',
            target: "Tian-Shang Chang's Organic Farm"
          },
          {
            source: '2020C1-Lu Ming Rice_Tian-Shang Chang',
            target: 'Harvesting And Milling'
          },
          { source: '2020C1-Lu Ming Rice_Tian-Shang Chang', target: 'Delivering' },
          {
            source: '2020C1-Lu Ming Rice_Tian-Shang Chang',
            target: 'Pesticide And Detecting'
          },
          {
            source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh',
            target: 'Rice Seed Transplantation'
          },
          {
            source: 'Rice Seed Transplantation',
            target: "Jin-Shi Hsieh's Organic Farm"
          },
          { source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh', target: 'Site Preparation' },
          { source: 'Site Preparation', target: "Jin-Shi Hsieh's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh', target: 'Irrigation' },
          { source: 'Irrigation', target: "Jin-Shi Hsieh's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh', target: 'Fertilization' },
          { source: 'Fertilization', target: "Jin-Shi Hsieh's Organic Farm" },
          {
            source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh',
            target: 'Biotic Suppression Operation'
          },
          {
            source: 'Biotic Suppression Operation',
            target: "Jin-Shi Hsieh's Organic Farm"
          },
          {
            source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh',
            target: 'Harvesting And Milling'
          },
          { source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh', target: 'Delivering' },
          {
            source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh',
            target: 'Pesticide And Detecting'
          },
          {
            source: '2020C1-Lu Ming Rice_Cheng-Po Hsu',
            target: 'Rice Seed Transplantation'
          },
          {
            source: 'Rice Seed Transplantation',
            target: "Cheng-Po Hsu's Organic Farm"
          },
          { source: '2020C1-Lu Ming Rice_Cheng-Po Hsu', target: 'Site Preparation' },
          { source: 'Site Preparation', target: "Cheng-Po Hsu's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Cheng-Po Hsu', target: 'Irrigation' },
          { source: 'Irrigation', target: "Cheng-Po Hsu's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Cheng-Po Hsu', target: 'Fertilization' },
          { source: 'Fertilization', target: "Cheng-Po Hsu's Organic Farm" },
          {
            source: '2020C1-Lu Ming Rice_Cheng-Po Hsu',
            target: 'Biotic Suppression Operation'
          },
          {
            source: 'Biotic Suppression Operation',
            target: "Cheng-Po Hsu's Organic Farm"
          },
          {
            source: '2020C1-Lu Ming Rice_Cheng-Po Hsu',
            target: 'Harvesting And Milling'
          },
          { source: '2020C1-Lu Ming Rice_Cheng-Po Hsu', target: 'Delivering' },
          {
            source: '2020C1-Lu Ming Rice_Cheng-Po Hsu',
            target: 'Pesticide And Detecting'
          },
          {
            source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh',
            target: 'Rice Seed Transplantation'
          },
          {
            source: 'Rice Seed Transplantation',
            target: "Jin-Nan Hsieh's Organic Farm"
          },
          { source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh', target: 'Site Preparation' },
          { source: 'Site Preparation', target: "Jin-Nan Hsieh's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh', target: 'Irrigation' },
          { source: 'Irrigation', target: "Jin-Nan Hsieh's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh', target: 'Fertilization' },
          { source: 'Fertilization', target: "Jin-Nan Hsieh's Organic Farm" },
          {
            source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh',
            target: 'Biotic Suppression Operation'
          },
          {
            source: 'Biotic Suppression Operation',
            target: "Jin-Nan Hsieh's Organic Farm"
          },
          {
            source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh',
            target: 'Harvesting And Milling'
          },
          { source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh', target: 'Delivering' },
          {
            source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh',
            target: 'Pesticide And Detecting'
          },
          { source: 'Harvesting And Milling', target: 'Rice House' },
          { source: 'Delivering', target: 'Rice House' },
          { source: 'Pesticide And Detecting', target: 'Rice House' },
          { source: 'Rice Seed Transplantation', target: 'Rice Transplanter' },
          { source: 'Site Preparation', target: 'Tractor' },
          { source: 'Irrigation', target: 'Irrigating' },
          { source: 'Irrigation', target: 'Drainaging' },
          { source: 'Fertilization', target: 'Fusow organic fertilizer 426' },
          { source: 'Fertilization', target: 'Ear fertilizer' },
          { source: 'Fertilization', target: 'Fusow special fertilizer for organic cultivation 522' },
          { source: 'Fertilization', target: 'Fagopyrum Esculentum Seed' },
          { source: 'Biotic Suppression Operation', target: 'Tea Seed Meal' },
          {
            source: 'Harvesting And Milling',
            target: 'Brown Rice Milling + Package'
          },
          { source: 'Harvesting And Milling', target: 'Milled Rice+Package' },
          { source: 'Harvesting And Milling', target: 'Low Temperature Paddy Dryer' },
          { source: 'Harvesting And Milling', target: 'Low Temperature Bin' },
          { source: 'Harvesting And Milling', target: 'Paddy complete harvester' },
          { source: 'Pesticide And Detecting', target: 'Pass' }
        ]
      },
      '2020C1-Lu Ming Rice_Tian-Shang Chang': {
        nodes: [
          {
            id: '2020C1-Lu Ming Rice_Tian-Shang Chang',
            groupId: 'farmer',
            size: 170,
            label: '2020C1-\nLu Ming Rice_\nTian-Shang Chang',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#fe917d' }
          }, // operations1
          {
            id: 'Rice Seed Transplantation',
            groupId: 'operation',
            size: 170,
            label: 'Rice Seed\n Transplantation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Site Preparation',
            groupId: 'operation',
            size: 170,
            label: 'Site Preparation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Irrigation',
            groupId: 'operation',
            size: 170,
            label: 'Irrigation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Fertilization',
            groupId: 'operation',
            size: 170,
            label: 'Fertilization',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Biotic Suppression Operation',
            groupId: 'operation',
            size: 170,
            label: 'Biotic\n Suppression Operation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          }, // operation2
          {
            id: 'Harvesting And Milling',
            groupId: 'operation',
            size: 170,
            label: 'Harvesting\n And Milling',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Delivering',
            groupId: 'operation',
            size: 170,
            label: 'Delivering',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Pesticide And Detecting',
            groupId: 'operation',
            size: 170,
            label: 'Pesticide\n And Detecting',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Rice House',
            groupId: 'site',
            size: 170,
            label: 'Rice House',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#ffffff' }
          },
          {
            id: 'Tian-Shang Chang\'s Organic Farm',
            groupId: 'site',
            size: 170,
            label: 'Tian-Shang Chang\'s\n Organic Farm',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#ffffff' }
          },
          {
            id: 'Rice Transplanter',
            groupId: 'operation_detail',
            size: 170,
            label: 'Rice \nTransplanter',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Tractor',
            groupId: 'operation_detail',
            size: 170,
            label: 'Tractor',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Irrigating',
            groupId: 'operation_detail',
            size: 170,
            label: 'Irrigating',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Drainaging',
            groupId: 'operation_detail',
            size: 170,
            label: 'Drainaging',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fusow organic fertilizer 426',
            groupId: 'operation_detail',
            size: 170,
            label: 'Fusow organic\n fertilizer 426',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Ear fertilizer',
            groupId: 'operation_detail',
            size: 170,
            label: 'Ear fertilizer',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fusow special fertilizer for organic cultivation 522',
            groupId: 'operation_detail',
            size: 170,
            label: 'Fusow special\n fertilizer for\n organic cultivation\n 522',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fagopyrum Esculentum Seed',
            groupId: 'operation_detail',
            size: 170,
            label: 'Fagopyrum \nEsculentum Seed',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Tea Seed Meal',
            groupId: 'operation_detail',
            size: 170,
            label: 'Tea Seed Meal',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Brown Rice Milling + Package',
            groupId: 'operation_detail',
            size: 170,
            label: 'Brown Rice \nMilling + Package',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Milled Rice+Package',
            groupId: 'operation_detail',
            size: 170,
            label: 'Milled Rice\n+Package',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Low Temperature Paddy Dryer',
            groupId: 'operation_detail',
            size: 170,
            label: 'Low Temperature\n Paddy Dryer',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Low Temperature Bin',
            groupId: 'operation_detail',
            size: 170,
            label: 'Low Temperature\n Bin',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Paddy complete harvester',
            groupId: 'operation_detail',
            size: 170,
            label: 'Paddy complete\n harvester',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Pass',
            groupId: 'operation_detail',
            size: 170,
            label: 'Pass',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          }
        ],
        edges: [
          {
            source: '2020C1-Lu Ming Rice_Tian-Shang Chang',
            target: 'Rice Seed Transplantation'
          },
          {
            source: 'Rice Seed Transplantation',
            target: "Tian-Shang Chang's Organic Farm"
          },
          {
            source: '2020C1-Lu Ming Rice_Tian-Shang Chang',
            target: 'Site Preparation'
          },
          { source: 'Site Preparation', target: "Tian-Shang Chang's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Tian-Shang Chang', target: 'Irrigation' },
          { source: 'Irrigation', target: "Tian-Shang Chang's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Tian-Shang Chang', target: 'Fertilization' },
          { source: 'Fertilization', target: "Tian-Shang Chang's Organic Farm" },
          {
            source: '2020C1-Lu Ming Rice_Tian-Shang Chang',
            target: 'Biotic Suppression Operation'
          },
          {
            source: 'Biotic Suppression Operation',
            target: "Tian-Shang Chang's Organic Farm"
          },
          {
            source: '2020C1-Lu Ming Rice_Tian-Shang Chang',
            target: 'Harvesting And Milling'
          },
          { source: '2020C1-Lu Ming Rice_Tian-Shang Chang', target: 'Delivering' },
          {
            source: '2020C1-Lu Ming Rice_Tian-Shang Chang',
            target: 'Pesticide And Detecting'
          },
          { source: 'Harvesting And Milling', target: 'Rice House' },
          { source: 'Delivering', target: 'Rice House' },
          { source: 'Pesticide And Detecting', target: 'Rice House' },
          { source: 'Rice Seed Transplantation', target: 'Rice Transplanter' },
          { source: 'Site Preparation', target: 'Tractor' },
          { source: 'Irrigation', target: 'Irrigating' },
          { source: 'Irrigation', target: 'Drainaging' },
          { source: 'Fertilization', target: 'Fusow organic fertilizer 426' },
          { source: 'Fertilization', target: 'Ear fertilizer' },
          {
            source: 'Fertilization',
            target: 'Fusow special fertilizer for organic cultivation 522'
          },
          { source: 'Fertilization', target: 'Fagopyrum Esculentum Seed' },
          { source: 'Biotic Suppression Operation', target: 'Tea Seed Meal' },
          {
            source: 'Harvesting And Milling',
            target: 'Brown Rice Milling + Package'
          },
          { source: 'Harvesting And Milling', target: 'Milled Rice+Package' },
          { source: 'Harvesting And Milling', target: 'Low Temperature Paddy Dryer' },
          { source: 'Harvesting And Milling', target: 'Low Temperature Bin' },
          { source: 'Harvesting And Milling', target: 'Paddy complete harvester' },
          { source: 'Pesticide And Detecting', target: 'Pass' }
        ]
      },
      '2020C1-Lu Ming Rice_Jin-Shi Hsieh': {
        nodes: [
          {
            id: '2020C1-Lu Ming Rice_Jin-Shi Hsieh',
            size: 170,
            label: '2020C1-\nLu Ming Rice_\nJin-Shi Hsieh',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#fe917d' }
          }, // operations1
          {
            id: 'Rice Seed Transplantation',
            size: 170,
            label: 'Rice Seed\n Transplantation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Site Preparation',
            size: 170,
            label: 'Site Preparation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Irrigation',
            size: 170,
            label: 'Irrigation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Fertilization',
            size: 170,
            label: 'Fertilization',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Biotic Suppression Operation',
            size: 170,
            label: 'Biotic\n Suppression Operation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          }, // operation2
          {
            id: 'Harvesting And Milling',
            size: 170,
            label: 'Harvesting\n And Milling',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Delivering',
            size: 170,
            label: 'Delivering',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Pesticide And Detecting',
            size: 170,
            label: 'Pesticide\n And Detecting',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Rice House',
            size: 170,
            label: 'Rice House',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#ffffff' }
          },
          {
            id: 'Jin-Shi Hsieh\'s Organic Farm',
            size: 170,
            label: 'Jin-Shi Hsieh\'s\n Organic Farm',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#ffffff' }
          },
          {
            id: 'Rice Transplanter',
            size: 170,
            label: 'Rice \nTransplanter',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Tractor',
            size: 170,
            label: 'Tractor',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Irrigating',
            size: 170,
            label: 'Irrigating',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Drainaging',
            size: 170,
            label: 'Drainaging',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fusow organic fertilizer 426',
            size: 170,
            label: 'Fusow organic\n fertilizer 426',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Ear fertilizer',
            size: 170,
            label: 'Ear fertilizer',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fusow special fertilizer for organic cultivation 522',
            size: 170,
            label: 'Fusow special\n fertilizer for\n organic cultivation\n 522',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fagopyrum Esculentum Seed',
            size: 170,
            label: 'Fagopyrum \nEsculentum Seed',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Tea Seed Meal',
            size: 170,
            label: 'Tea Seed Meal',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Brown Rice Milling + Package',
            size: 170,
            label: 'Brown Rice \nMilling + Package',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Milled Rice+Package',
            size: 170,
            label: 'Milled Rice\n+Package',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Low Temperature Paddy Dryer',
            size: 170,
            label: 'Low Temperature\n Paddy Dryer',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Low Temperature Bin',
            size: 170,
            label: 'Low Temperature\n Bin',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Paddy complete harvester',
            size: 170,
            label: 'Paddy complete\n harvester',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Pass',
            size: 170,
            label: 'Pass',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          }
        ],
        edges: [
          {
            source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh',
            target: 'Rice Seed Transplantation'
          },
          {
            source: 'Rice Seed Transplantation',
            target: "Jin-Shi Hsieh's Organic Farm"
          },
          { source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh', target: 'Site Preparation' },
          { source: 'Site Preparation', target: "Jin-Shi Hsieh's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh', target: 'Irrigation' },
          { source: 'Irrigation', target: "Jin-Shi Hsieh's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh', target: 'Fertilization' },
          { source: 'Fertilization', target: "Jin-Shi Hsieh's Organic Farm" },
          {
            source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh',
            target: 'Biotic Suppression Operation'
          },
          {
            source: 'Biotic Suppression Operation',
            target: "Jin-Shi Hsieh's Organic Farm"
          },
          {
            source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh',
            target: 'Harvesting And Milling'
          },
          { source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh', target: 'Delivering' },
          {
            source: '2020C1-Lu Ming Rice_Jin-Shi Hsieh',
            target: 'Pesticide And Detecting'
          },
          { source: 'Harvesting And Milling', target: 'Rice House' },
          { source: 'Delivering', target: 'Rice House' },
          { source: 'Pesticide And Detecting', target: 'Rice House' }, { source: 'Pesticide And Detecting', target: 'Rice House' },
          { source: 'Rice Seed Transplantation', target: 'Rice Transplanter' },
          { source: 'Site Preparation', target: 'Tractor' },
          { source: 'Irrigation', target: 'Irrigating' },
          { source: 'Irrigation', target: 'Drainaging' },
          { source: 'Fertilization', target: 'Fusow organic fertilizer 426' },
          { source: 'Fertilization', target: 'Ear fertilizer' },
          {
            source: 'Fertilization',
            target: 'Fusow special fertilizer for organic cultivation 522'
          },
          { source: 'Fertilization', target: 'Fagopyrum Esculentum Seed' },
          { source: 'Biotic Suppression Operation', target: 'Tea Seed Meal' },
          {
            source: 'Harvesting And Milling',
            target: 'Brown Rice Milling + Package'
          },
          { source: 'Harvesting And Milling', target: 'Milled Rice+Package' },
          { source: 'Harvesting And Milling', target: 'Low Temperature Paddy Dryer' },
          { source: 'Harvesting And Milling', target: 'Low Temperature Bin' },
          { source: 'Harvesting And Milling', target: 'Paddy complete harvester' },
          { source: 'Pesticide And Detecting', target: 'Pass' }
        ]
      },
      '2020C1-Lu Ming Rice_Cheng-Po Hsu': {
        nodes: [
          {
            id: '2020C1-Lu Ming Rice_Cheng-Po Hsu',
            size: 170,
            label: '2020C1-\nLu Ming Rice_\nCheng-Po Hsu',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#fe917d' }
          }, // operations1
          {
            id: 'Rice Seed Transplantation',
            size: 170,
            label: 'Rice Seed\n Transplantation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Site Preparation',
            size: 170,
            label: 'Site Preparation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Irrigation',
            size: 170,
            label: 'Irrigation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Fertilization',
            size: 170,
            label: 'Fertilization',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Biotic Suppression Operation',
            size: 170,
            label: 'Biotic\n Suppression Operation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          }, // operation2
          {
            id: 'Harvesting And Milling',
            size: 170,
            label: 'Harvesting\n And Milling',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Delivering',
            size: 170,
            label: 'Delivering',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Pesticide And Detecting',
            size: 170,
            label: 'Pesticide\n And Detecting',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Rice House',
            size: 170,
            label: 'Rice House',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#ffffff' }
          },
          {
            id: 'Cheng-Po Hsu\'s Organic Farm',
            size: 170,
            label: 'Cheng-Po Hsu\'s\n Organic Farm',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#ffffff' }
          },
          {
            id: 'Rice Transplanter',
            size: 170,
            label: 'Rice \nTransplanter',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Tractor',
            size: 170,
            label: 'Tractor',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Irrigating',
            size: 170,
            label: 'Irrigating',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Drainaging',
            size: 170,
            label: 'Drainaging',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fusow organic fertilizer 426',
            size: 170,
            label: 'Fusow organic\n fertilizer 426',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Ear fertilizer',
            size: 170,
            label: 'Ear fertilizer',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fusow special fertilizer for organic cultivation 522',
            size: 170,
            label: 'Fusow special\n fertilizer for\n organic cultivation\n 522',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fagopyrum Esculentum Seed',
            size: 170,
            label: 'Fagopyrum \nEsculentum Seed',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Tea Seed Meal',
            size: 170,
            label: 'Tea Seed Meal',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Brown Rice Milling + Package',
            size: 170,
            label: 'Brown Rice \nMilling + Package',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Milled Rice+Package',
            size: 170,
            label: 'Milled Rice\n+Package',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Low Temperature Paddy Dryer',
            size: 170,
            label: 'Low Temperature\n Paddy Dryer',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Low Temperature Bin',
            size: 170,
            label: 'Low Temperature\n Bin',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Paddy complete harvester',
            size: 170,
            label: 'Paddy complete\n harvester',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Pass',
            size: 170,
            label: 'Pass',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          }
        ],
        edges: [
          {
            source: '2020C1-Lu Ming Rice_Cheng-Po Hsu',
            target: 'Rice Seed Transplantation'
          },
          {
            source: 'Rice Seed Transplantation',
            target: "Cheng-Po Hsu's Organic Farm"
          },
          { source: '2020C1-Lu Ming Rice_Cheng-Po Hsu', target: 'Site Preparation' },
          { source: 'Site Preparation', target: "Cheng-Po Hsu's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Cheng-Po Hsu', target: 'Irrigation' },
          { source: 'Irrigation', target: "Cheng-Po Hsu's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Cheng-Po Hsu', target: 'Fertilization' },
          { source: 'Fertilization', target: "Cheng-Po Hsu's Organic Farm" },
          {
            source: '2020C1-Lu Ming Rice_Cheng-Po Hsu',
            target: 'Biotic Suppression Operation'
          },
          {
            source: 'Biotic Suppression Operation',
            target: "Cheng-Po Hsu's Organic Farm"
          },
          {
            source: '2020C1-Lu Ming Rice_Cheng-Po Hsu',
            target: 'Harvesting And Milling'
          },
          { source: '2020C1-Lu Ming Rice_Cheng-Po Hsu', target: 'Delivering' },
          {
            source: '2020C1-Lu Ming Rice_Cheng-Po Hsu',
            target: 'Pesticide And Detecting'
          },
          { source: 'Harvesting And Milling', target: 'Rice House' },
          { source: 'Delivering', target: 'Rice House' },
          { source: 'Pesticide And Detecting', target: 'Rice House' },
          { source: 'Rice Seed Transplantation', target: 'Rice Transplanter' },
          { source: 'Site Preparation', target: 'Tractor' },
          { source: 'Irrigation', target: 'Irrigating' },
          { source: 'Irrigation', target: 'Drainaging' },
          { source: 'Fertilization', target: 'Fusow organic fertilizer 426' },
          { source: 'Fertilization', target: 'Ear fertilizer' },
          {
            source: 'Fertilization',
            target: 'Fusow special fertilizer for organic cultivation 522'
          },
          { source: 'Fertilization', target: 'Fagopyrum Esculentum Seed' },
          { source: 'Biotic Suppression Operation', target: 'Tea Seed Meal' },
          {
            source: 'Harvesting And Milling',
            target: 'Brown Rice Milling + Package'
          },
          { source: 'Harvesting And Milling', target: 'Milled Rice+Package' },
          { source: 'Harvesting And Milling', target: 'Low Temperature Paddy Dryer' },
          { source: 'Harvesting And Milling', target: 'Low Temperature Bin' },
          { source: 'Harvesting And Milling', target: 'Paddy complete harvester' },
          { source: 'Pesticide And Detecting', target: 'Pass' }
        ]
      },
      '2020C1-Lu Ming Rice_Jin-Nan Hsieh': {
        nodes: [
          {
            id: '2020C1-Lu Ming Rice_Jin-Nan Hsieh',
            size: 170,
            label: '2020C1-\nLu Ming Rice_\nJin-Nan Hsieh',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#fe917d' }
          }, // operations1
          {
            id: 'Rice Seed Transplantation',
            size: 170,
            label: 'Rice Seed\n Transplantation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Site Preparation',
            size: 170,
            label: 'Site Preparation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Irrigation',
            size: 170,
            label: 'Irrigation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Fertilization',
            size: 170,
            label: 'Fertilization',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Biotic Suppression Operation',
            size: 170,
            label: 'Biotic\n Suppression Operation',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          }, // operation2
          {
            id: 'Harvesting And Milling',
            size: 170,
            label: 'Harvesting\n And Milling',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Delivering',
            size: 170,
            label: 'Delivering',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Pesticide And Detecting',
            size: 170,
            label: 'Pesticide\n And Detecting',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#85d0a0' }
          },
          {
            id: 'Rice House',
            size: 170,
            label: 'Rice House',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#ffffff' }
          },
          {
            id: 'Jin-Nan Hsieh\'s Organic Farm',
            size: 170,
            label: 'Jin-Nan Hsieh\'s\n Organic Farm',
            labelCfg: { style: { fontSize: 16 } },
            style: { stroke: '#413960', fill: '#ffffff' }
          },
          {
            id: 'Rice Transplanter',
            size: 170,
            label: 'Rice \nTransplanter',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Tractor',
            size: 170,
            label: 'Tractor',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Irrigating',
            size: 170,
            label: 'Irrigating',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Drainaging',
            size: 170,
            label: 'Drainaging',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fusow organic fertilizer 426',
            size: 170,
            label: 'Fusow organic\n fertilizer 426',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Ear fertilizer',
            size: 170,
            label: 'Ear fertilizer',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fusow special fertilizer for organic cultivation 522',
            size: 170,
            label: 'Fusow special\n fertilizer for\n organic cultivation\n 522',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Fagopyrum Esculentum Seed',
            size: 170,
            label: 'Fagopyrum \nEsculentum Seed',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Tea Seed Meal',
            size: 170,
            label: 'Tea Seed Meal',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Brown Rice Milling + Package',
            size: 170,
            label: 'Brown Rice \nMilling + Package',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Milled Rice+Package',
            size: 170,
            label: 'Milled Rice\n+Package',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Low Temperature Paddy Dryer',
            size: 170,
            label: 'Low Temperature\n Paddy Dryer',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Low Temperature Bin',
            size: 170,
            label: 'Low Temperature\n Bin',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Paddy complete harvester',
            size: 170,
            label: 'Paddy complete\n harvester',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          },
          {
            id: 'Pass',
            size: 170,
            label: 'Pass',
            labelCfg: { style: { fontSize: 16, fill: '#ffffff' } },
            style: { stroke: '#413960', fill: '#6154a5' }
          }
        ],
        edges: [
          {
            source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh',
            target: 'Rice Seed Transplantation'
          },
          {
            source: 'Rice Seed Transplantation',
            target: "Jin-Nan Hsieh's Organic Farm"
          },
          { source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh', target: 'Site Preparation' },
          { source: 'Site Preparation', target: "Jin-Nan Hsieh's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh', target: 'Irrigation' },
          { source: 'Irrigation', target: "Jin-Nan Hsieh's Organic Farm" },
          { source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh', target: 'Fertilization' },
          { source: 'Fertilization', target: "Jin-Nan Hsieh's Organic Farm" },
          {
            source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh',
            target: 'Biotic Suppression Operation'
          },
          {
            source: 'Biotic Suppression Operation',
            target: "Jin-Nan Hsieh's Organic Farm"
          },
          {
            source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh',
            target: 'Harvesting And Milling'
          },
          { source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh', target: 'Delivering' },
          {
            source: '2020C1-Lu Ming Rice_Jin-Nan Hsieh',
            target: 'Pesticide And Detecting'
          },
          { source: 'Harvesting And Milling', target: 'Rice House' },
          { source: 'Delivering', target: 'Rice House' },
          { source: 'Pesticide And Detecting', target: 'Rice House' },
          { source: 'Rice Seed Transplantation', target: 'Rice Transplanter' },
          { source: 'Site Preparation', target: 'Tractor' },
          { source: 'Irrigation', target: 'Irrigating' },
          { source: 'Irrigation', target: 'Drainaging' },
          { source: 'Fertilization', target: 'Fusow organic fertilizer 426' },
          { source: 'Fertilization', target: 'Ear fertilizer' },
          {
            source: 'Fertilization',
            target: 'Fusow special fertilizer for organic cultivation 522'
          },
          { source: 'Fertilization', target: 'Fagopyrum Esculentum Seed' },
          { source: 'Biotic Suppression Operation', target: 'Tea Seed Meal' },
          {
            source: 'Harvesting And Milling',
            target: 'Brown Rice Milling + Package'
          },
          { source: 'Harvesting And Milling', target: 'Milled Rice+Package' },
          { source: 'Harvesting And Milling', target: 'Low Temperature Paddy Dryer' },
          { source: 'Harvesting And Milling', target: 'Low Temperature Bin' },
          { source: 'Harvesting And Milling', target: 'Paddy complete harvester' },
          { source: 'Pesticide And Detecting', target: 'Pass' }
        ]
      }
    }
  },
  // get
  getters: {
    getData: state => {
      return state.testState
    }
  },
  // for sync func用途
  mutations: {
    setData: (state, res) => {
      console.log('Set data mutation')
      state.testState = res
      // checkpoint
      console.log(state.testState)
    }
  },
  // for async func用途
  actions: {
    getData: async context => {
      // 承接json header設定
      // const userRequest = Axios.create({
      //   baseURL: 'https://www.for-paper-proxy.binyi.fun/',
      //   headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
      // })

      const userRequest = Axios.create({
        baseURL: 'https://www.for-paper-proxy.binyi.fun',
        headers: { Accept: 'html/text' }
      })
      console.log('get data action')
      await userRequest.get('/hello2').then(res => {
        console.log('this is in the promise')
        context.commit('setData', res)
      }).catch(err => {
        console.log(err)
      })
    }
  },
  modules: {}
})

import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '@/views/About'
import Search from '@/views/Search'
import Documentation from '@/views/Documentation'
import Platform from '@/views/Platform'
import Records from '@/views/Records'
import Metaverse from '@/views/Metaverse'
import VirtualRecord from '@/views/VirtualRecord'
import Terms from '@/views/Terms'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: About
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/traceability',
    name: 'Platform',
    component: Platform
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: Documentation
  },
  {
    path: '/metaverse',
    name: 'Metaverse',
    component: Metaverse
  },
  {
    path: '/3d-records',
    name: '3DRecords',
    component: VirtualRecord
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/records',
    name: 'Records',
    component: Records
  }
]

const router = new VueRouter({
  routes
})

export default router

<template>
  <footer class="flex flex-col justify-center bg-o_purple-100 text-t_white text-lg py-7 pb-9 rounded-2xl">
      <p class="mt-2 text-center" v-for="info in footers" :key="info">{{ info }}</p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data: function () {
    return {
      footers: [
        'This project is supported by Council of Agriculture, Executive Yuan',
        'Maintained by Department of Bioenvironmental Systems Engineering',
        'No. 1, Sec. 4, Roosevelt Road, Taipei, 10617 Taiwan(R.O.C)',
        'Service line：+886-2-33663468',
        'Version: 1.1.2 updated at 2022/10/27 10:00'
      ]
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-3.5 h-full">
    <div v-for="content in contents" :key="content.h1">
      <h1 class="text-o_purple-200  text-4xl font-semibold my-4">
        {{ content.h1 }}
      </h1>
      <h2 v-if="content.h2" class="font-semibold text-3xl text-left text-o_green px-10 mt-2 border-l-8 border-blue-100"
          v-html="content.h2">
      </h2>
      <p class="text-2xl text-left text-o_purple-100 px-5  mt-8"
         v-html="content.content">
      </p>
    </div>
    <div>
      <h2 class="font-semibold text-3xl text-left text-o_green px-10 mt-2 border-l-8 border-blue-100">
        {{ core_value.h1 }}
      </h2>
      <ul class="flex flex-row w-60 pl-6 mb-6">
        <li class="flex flex-col mt-6 mr-14" v-for="item in core_value.values" :key="item.name">
          <h2 class="text-o_pink text-3xl font-semibold mb-3">{{ item.name }}</h2>
          <h3 class="rounded-full bg-o_purple-200 w-48 h-48 p-11">
            <img :src="item.path" alt="">
          </h3>
          <p class="text-2xl text-o_purple-100">
            {{ item.content }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data: function () {
    return {
      contents: [
        {
          h1: 'What is traceability?',
          h2: 'Agricultural Traceability System Implementation and <br>' +
            'Verification of Taiwan Good Agriculture Practice & Agricultural Traceability System',
          content: 'According to the voluntary agricultural product traceability system implemented by the ' +
            '“Agricultural Production and Certification Act,” we adopt Taiwan Good Agriculture Practice ' +
            '(TGAP) for implementation and verification and establish the product traceability system. ' +
            'In short, agricultural products with labels marked by the traceability system can not only provide us ' +
            'the records of crop growth via ' +
            '<a class="text-o_pink font-semibold transform hover:underline transition:ease-out duration-300" href="https://taft.coa.gov.tw/default.html">' +
            '“Agricultural Products Traceability System Website,”</a> ' +
            'but also represent the fact that the verification organization has confirmed if the farming ' +
            'activities follow the regulations on the farmlands; we also sample and inspect the agricultural ' +
            'products. In addition, related records of each batch of product are under the monitoring of' +
            ' verification organizations and are strictly examined. Any problems will be solved once they ' +
            'emerge, which make us able to reduce the risk of forging false information and control ' +
            'the environment of production so that the process of production does not do harm to the ' +
            'environment, and neither do the agricultural products to human bodies.'
        },
        {
          h1: 'What is blockchain?',
          content: 'Blockchain is a decentralized-ledger technique which is established on peer-to-peer (P2P) Internet.' +
            ' Participant can set up a node on his or her own computer and update the data together with other nodes.' +
            ' The encryption technique derived from cryptography is applied to realize a dataset ' +
            'which is incorruptible. Due to the reason that blockchain is able to record and ' +
            'verify each set of data in detail, it is suitable to be applied in the traceability ' +
            'system of agricultural products. '
        }
      ],
      core_value: {
        h1: 'Core values',
        values: [
          {
            name: 'Traceability',
            path: require('../assets/icons/core_img01.svg'),
            content: 'Blockchain record each set of data indetail, and is able to serve as the traceable dataset of agricultural products.'
          },
          {
            name: 'Decentralization',
            path: require('../assets/icons/core_img02.svg'),
            content: 'Traditional dataset is controlled by a single server, while that with blockchain is controlled and manage by all the nodes from the participants.'
          },
          {
            name: 'Immutability',
            path: require('../assets/icons/core_img03.svg'),
            content: 'The correctness of data can be verified via the mechanism of cryptography.'
          },
          {
            name: 'Transparent',
            path: require('../assets/icons/core_img04.svg'),
            content: 'All the data is transparent, and everyone have access to those data put on the blockchain.'
          }
        ]

      }
    }
  }
}
</script>

<style scoped>

</style>

<template xmlns:wire="http://www.w3.org/1999/xhtml">
  <div class="relative grid grid-cols-8 grid-rows-7 grid-flow-col gap-1.5 mt-3 custom-h-inside">
    <div class="rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-2 row-span-5 overflow-auto p-6">
      <h1 class="text-o_purple-200 text-3xl font-semibold mb-2">3D Records</h1>
      <ul v-show="recordInfoActive"  v-for="(record3D, index) in records" :key="'record3D' + index" class="bg-white border-b-4 border-t_dark_brown mt-6 mb-6">
        <h1 class="text-o_purple-200 text-2xl font-semibold">
          {{ record3D.operation }}
        </h1>
        <h1 class="text-right mb-4 pt-4 ">
          <span class="px-6 inline-flex text-2xl leading-15 font-semibold rounded-full bg-green-100 text-green-800">
            Verified
          </span>
        </h1>
        <span class="font-semibold text-2xl text-o_purple-100">Code: </span><br>
        <div class="mt-2 pb-3.5 text-2xl text-o_purple-100">{{ record3D.product_code }}</div>
        <span class="font-semibold text-2xl text-o_purple-100">Time: </span><br>
        <div class="mt-2 pb-3.5 text-2xl text-o_purple-100">{{ record3D.time }}</div>
        <span class="font-semibold text-2xl text-o_purple-100">Place: </span><br>
        <div class="mt-2 pb-3.5 text-2xl text-o_purple-100">{{ record3D.place }}</div>
        <button v-if="true"
                @click="show3DRecord(record3D.operation, record3D.video, $event)"
                type="button"
                class="font-semibold
              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100 mt-3 mb-3 ">
          3D Record
        </button>
      </ul>
    </div>
    <div v-show="isLoading" wire:loading class="rounded-2xl absolute top-0 left-0 right-0 bottom-0 w-full h-full z-50 overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <p class="w-1/3 text-center text-white">It will take some time, please do not close this page or do other things</p>
    </div>
    <div class="rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-6 row-span-full overflow-auto p-6 z-0">
      <h1 class="text-o_purple-200 text-3xl font-semibold mb-3">3D Animation Record: {{currentOperationType}}</h1>
<!--      <p class="mt-2 pb-3.5 text-xl text-o_purple-100"> Click a play button of a 3D record at the right panel, then the mapped animation of the equipment will be played.</p>-->
      <!-- 有設置一個key變化，這樣每次if顯示就會產生一個video reload重新開始  -->
      <video v-if="videoActive" :key="videoComponentKey"  ref="videoComponent" class="w-full" controls>
        <source :src="currentAnimationRecordURL" type="video/mp4">
      </video>
    </div>
  </div>
</template>

<script>
import Axios from 'axios'
import Config from '../config/config'
export default {
  name: 'VirtualRecords',
  data: function () {
    return {
      isLoading: false,
      videoComponentKey: 0,
      recordInfoActive: false,
      videoActive: false,
      recordPanel: false,
      currentAnimationRecordURL: '',
      currentOperationType: '',
      records: []
    }
  },
  methods: {
    async GetRecordContentWithoutAuthByProductAndOperations (product) {
      this.currentOperationType = ''
      this.currentAnimationRecordURL = ''
      this.tableActive = false
      this.videoActive = false
      // console.log('Getting record content ...')
      const dataForm = new FormData()
      dataForm.append('product', product)
      // dataForm.append('operations', '[\'Paddy complete harvester\',\'Tractor\',\'Fwusow organic fertilizer 426\',\'Fwusow special fertilizer for organic cultivation 522\',\'Ear fertilizer\']')
      const recordRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await recordRequest.post('/operation/get-records-by-product-with-video-url-path', dataForm).then(res => {
        const resTemp = res.data
        // console.log('return')
        // console.log(res)
        // 這個API回傳僅有一個result array因此錯誤為null反之則有內容，有內容就執行
        if (resTemp) {
          this.records = resTemp
          // console.log(resTemp)
          this.recordPanel = true
        } else {
          this.recordPanel = false
        }
      }).catch(err => {
        if (err.response) {
          this.recordPanel = false
          return false
        }
      })
    },
    async show3DRecord (operation, videoURL, event) {
      // 有設置一個key變化，這樣每次if顯示就會產生一個video reload重新開始
      this.videoComponentKey += 1
      this.currentOperationType = ''
      this.currentAnimationRecordURL = ''
      this.videoActive = false
      // const baseURL = 'https://www.for-paper-video.binyi.fun/videos/'
      const baseURL = Config.videoStaticBackendBaseURL
      this.currentAnimationRecordURL = baseURL + videoURL
      this.currentOperationType = operation
      // console.log(this.currentAnimationRecordURL)
      // const dataForm = new FormData()
      // dataForm.append('product', id)
      // console.log('3D Record Id is ' + id)
      // const animationRecordURLGet = Axios.create({
      //   baseURL: 'https://www.for-paper-proxy.binyi.fun/',
      //   // Accept: 'application/json' 表示要接收的是JSON
      //   headers: { Accept: 'application/json' }
      // })
      // await animationRecordURLGet.post('/operation/get-records-by-product-and-operations', dataForm).then(res => {
      //   const resTemp = res.data
      //   // 這個API回傳僅有一個result array因此錯誤為null反之則有內容，有內容就執行
      //   if (resTemp) {
      //     this.currentAnimationRecordURL = resTemp
      //     // console.log(resTemp)
      //     this.videoActive = true
      //   } else {
      //     this.videoActive = false
      //   }
      // }).catch(err => {
      //   if (err.response) {
      //     this.videoActive = false
      //     return false
      //   }
      // })
      this.videoActive = true
    }
  }
}
</script>

<style scoped>
.custom-h-inside {
  height: 1200px;
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <div>
    <!-- controlling area   -->
    <div class="sticky top-0 bg-gray-50 border-b-4 pb-1.5 z-50">
      <div class="flex pt-2">
        <div class="flex flex-row items-center ml-4">
          <label id="listbox-label1" class="block text-3xl text-o_purple-100 font-semibold mr-2">
            Farm
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="isActive1 = !isActive1" type="button"
                      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
                {{ select_state1 }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>

            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="isActive1" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a @click="selectEvent"
                   v-for="f_option in farms" :key="f_option"
                   href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ f_option }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="searchBar2" class="flex flex-row items-center ml-4">
          <label id="listbox-label2" class="block text-3xl font-semibold text-o_purple-100 mr-2">
            Products
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="isActive2 = !isActive2" type="button"
                      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm
                      px-4 py-2 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100"
                      id="options-menu2" aria-haspopup="true" aria-expanded="true">
                {{ select_state2 }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>

            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="isActive2" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a @click="selectEvent2"
                   v-for="p_option in products" :key="p_option"
                   href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ p_option }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="searchBar3" class="flex flex-row items-center ml-4">
          <label id="listbox-label3" class="block text-3xl font-semibold text-o_purple-100 mr-2">
            Farmers
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="isActive3 = !isActive3" type="button"
                      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm
                      px-4 py-2 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100"
                      id="options-menu3" aria-haspopup="true" aria-expanded="true">
                {{ select_state3 }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>

            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="isActive3" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a @click="selectEvent3"
                   v-for="p_option in farmers" :key="p_option"
                   href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ p_option }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      萬一之後要再多加一個下拉選單 or search bar那可以考慮用下面編排，但要清掉上面的Back/Previous/Next/Level button -->
      <!--      <div class="flex flex-row-reverse mt-3 mr-1 mb-1.5">-->
      <!--        <button @click="BackAllGraph"-->
      <!--                v-if="node_selected"-->
      <!--                type="button"-->
      <!--                class=" ml-1.5  font-semibold-->
      <!--                inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md-->
      <!--                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2-->
      <!--                focus:ring-offset-2 focus:ring-o_purple-100">-->
      <!--          Back-->
      <!--        </button>-->
      <!--        <button @click="DownLevel(nodeName)"-->
      <!--                v-if="node_selected"-->
      <!--                type="button"-->
      <!--                class=" ml-1.5 font-semibold-->
      <!--                inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md-->
      <!--                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2-->
      <!--                focus:ring-offset-2 focus:ring-o_purple-100">-->
      <!--          Previous-->
      <!--        </button>-->
      <!--        <button @click="UpLevel(nodeName)"-->
      <!--                v-if="node_selected"-->
      <!--                type="button"-->
      <!--                class=" ml-1.5 font-semibold-->
      <!--                inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md-->
      <!--                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2-->
      <!--                focus:ring-offset-2 focus:ring-o_purple-100">-->
      <!--          Next-->
      <!--        </button>-->
      <!--        <button @click="MaskGraph(nodeName)"-->
      <!--                v-if="node_selected"-->
      <!--                type="button"-->
      <!--                class=" ml-1.5 font-semibold-->
      <!--                inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md-->
      <!--                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2-->
      <!--                focus:ring-offset-2 focus:ring-o_purple-100">-->
      <!--          Level-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
    <!-- 資訊頁面: 日曆、紀錄 -->
    <div class="grid grid-cols-8 grid-rows-7 grid-flow-col gap-1.5 mt-5 custom-h-inside">
      <div class="rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-2 row-span-full overflow-auto p-6 z-0">
        <FunctionalCalendar v-show="calendarActive"
                            @dayClicked="dayClickedEvent"
                            :key="markedDates"
                            :is-date-range="false"
                            :date-format="'yyyy-mm-dd'"
                            :change-month-function="true"
                            :change-year-function="true"
                            :markedDates="markedDates"
                            :newCurrentDate = "newCurrentDate"
                            class="calendar multiple"
                            ref="Calendar"
                            :alwaysUseDefaultClasses="false"></FunctionalCalendar>
      </div>
      <div class="grid grid-rows-7 col-span-6 row-span-full gap-1.5">
        <div class="rounded-2xl bg-white shadow-2xl mx-2 my-2 row-span-3 overflow-auto p-6" ref="canvas">
          <ul v-show="recordTimeActive" class="text-o_purple-100 text-3xl my-3">
            <li v-for="(record, index) in recordTime" :key="'record' + index" class="text-o_purple-100 text-3xl mb-4  mt-1 pt-4 border-b-4 border-t_dark_brown">
              <h1 class="text-right">
                  <span v-if="true" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-green-100 text-green-800">
                    verified
                  </span>
                  <span v-if="false" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-red-100 text-red-800">
                    unverified
                  </span>
<!--                <span v-if="record.error" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-green-100 text-green-800">-->
<!--                    已註銷-->
<!--                  </span>-->
<!--                <span v-if="!record.error" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-red-100 text-red-800">-->
<!--                    未註銷-->
<!--                  </span>-->
              </h1>
              <span class="font-semibold">Code:</span><br>
              <div class="pb-3.5">{{ record.product_code }}</div>
              <span class="font-semibold">Operation:</span><br>
              <div class="pb-3.5">{{ record.operation }}</div>
              <span class="font-semibold">Time:</span><br>
              <div class="pb-3.5">{{ record.time }}</div>
              <span class="font-semibold">Place:</span><br>
              <div class="pb-3.5">{{ record.place }}</div>
            </li>
          </ul>
        </div>
        <div class="rounded-2xl bg-white shadow-2xl mx-2 my-2 row-span-4 overflow-auto p-6" ref="canvas">
          <div class="flex flex-col m-3 my-6">
            <div class="-my-2 sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div v-show="tableActive" class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mb-5">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Id
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Time
                      </th>
                      <th scope="col" class="pl-2 pr-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Location
                      </th>
                      <th scope="col" class="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Operation
                      </th>
                      <th scope="col" class="pl-1 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Product
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Code
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Verified
                      </th>
                    </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="(content, index) in contents" :key="index">
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ content.record_id }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ content.time }}
                      </td>
                      <td class="pl-2 pr-1 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ content.place }}
                      </td>
                      <td class="py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ content.operation }}
                      </td>
                      <td class="pl-1 pr-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ select_state2 }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ content.product_code }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span v-if="!true" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    unverified
                  </span>
                        <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    verified
                  </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios'
// 日曆相關
import { FunctionalCalendar } from 'vue-functional-calendar'
import Config from '../config/config'
export default {
  name: 'Records',
  data: function () {
    return {
      farms: [],
      products: [],
      productList: {},
      select_state1: 'Please select',
      select_state2: 'Please select',
      select_state3: 'Please select',
      searchBar2: false,
      searchBar3: false,
      isActive1: false,
      isActive2: false,
      isActive3: false,
      selectedTime: '',
      recordTimeActive: false,
      calendarActive: false,
      tableActive: false,
      recordTime: {},
      contents: [],
      newCurrentDate: new Date(2021, 10, 10),
      // 日曆相關
      markedDates: ['2020-07-21']
    }
  },
  created: function () {
    this.farms = this.$store.state.farmlist
    this.node_infos = this.$store.state.nodeInfo
  },
  methods: {
    // 取得選單內容from database
    async GetMenuList () {
      const menuListRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await menuListRequest.get('/menu/list').then(res => {
        this.select_state1 = 'Rice House'
        this.isActive1 = !this.isActive1
        this.productList = res.data
        const root = [Object.keys(this.productList)]
        let productList = []
        productList = productList.concat(...root)
        this.products = productList
      })
    },
    GetMenuList2 (root) {
      let farmerList = []
      // farmerList = farmerList.concat(root)
      if (this.productList[root].length > 0) {
        farmerList = farmerList.concat('All Farmers')
        farmerList = farmerList.concat(...this.productList[root])
      } else {
        farmerList = []
      }
      this.farmers = farmerList
    },
    async GetRecordDates (productCode) {
      const dataForm = new FormData()
      if (productCode === 'All Farmers') {
        dataForm.append('product_code', 'codes')
      } else {
        dataForm.append('product_code', productCode)
      }
      dataForm.append('product', this.select_state2)
      const recordDatesRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await recordDatesRequest.post('/operation/get-dates-by-product-operation', dataForm).then(res => {
        const resTemp = res.data
        // 這個API回傳僅有一個result array因此錯誤為null反之則有內容，有內容就執行
        if (resTemp) {
          this.markedDates = resTemp.result
          const smallestDate = new Date(this.markedDates[0])
          this.newCurrentDate = smallestDate
          this.calendarActive = true
        }
      }).catch(err => {
        if (err.response) {
          return false
        }
      })
    },
    async showRecordsByDate (day) {
      this.recordTimeActive = false
      this.selectedTime = day
      const dataForm = new FormData()
      dataForm.append('day', day)
      const userRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'html/text' 表示要接收的是text
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await userRequest.post('/operation/get-records-by-day', dataForm).then(res => {
        if (res.data.result) {
          this.recordTimeActive = true
          this.recordTime = res.data.result
        }
      })
    },
    selectEvent (event) {
      // const i = this.$store.state.graphInstance
      // i.read(this.$store.state.graphs.LMR.g2)
      this.GetMenuList()
      this.tableActive = false
      this.contents = []
      // this.select_state1 = event.target.innerText
      // this.isActive1 = !this.isActive1
      // this.products = this.$store.state.cardList[this.select_state1]
      this.searchBar2 = true
      this.searchBar3 = false
      this.select_state2 = 'Please select'
    },
    async selectEvent2 (event) {
      this.tableActive = false
      this.contents = []
      this.recordTimeActive = false
      this.calendarActive = false
      this.operationCarbonActive = false
      this.searchBar3 = true
      this.select_state2 = event.target.innerText
      this.isActive2 = !this.isActive2
      this.GetMenuList2(this.select_state2)
      this.select_state3 = 'Please select'
      await this.GetRecordContentWithoutAuthByNodeTag(this.select_state2)
    },
    async selectEvent3 (event) {
      this.select_state3 = event.target.innerText
      this.isActive3 = !this.isActive3
      this.calendarActive = false
      await this.GetRecordDates(this.select_state3)
    },
    async dayClickedEvent (val) {
      // console.log('hi!')
      // console.log(val.date)
      const date = new Date(val.date)
      var year = date.getFullYear()
      var month = String(date.getMonth() + 1).padStart(2, '0')
      var day = String(date.getDate()).padStart(2, '0')
      var dayString = year + '-' + month + '-' + day
      // console.log(dayString)
      // console.log('%s-%s-%s', year, month, day)
      await this.showRecordsByDate(dayString)
    },
    async GetRecordContentWithoutAuthByNodeTag (product) {
      this.tableActive = false
      // console.log('Getting record content ...')
      const dataForm = new FormData()
      dataForm.append('product', product)
      const nodeInfoRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await nodeInfoRequest.post('/operation/get-records-by-product', dataForm).then(res => {
        const resTemp = res.data
        // console.log('return')
        // console.log(res)
        // 這個API回傳僅有一個result array因此錯誤為null反之則有內容，有內容就執行
        if (resTemp) {
          this.contents = resTemp
          this.tableActive = true
        } else {
          this.tableActive = false
        }
      }).catch(err => {
        if (err.response) {
          this.tableActive = false
          return false
        }
      })
    }
  },
  components: {
    // 日曆相關
    FunctionalCalendar
  }
}
</script>

<style scoped>
.custom-h-inside {
  height: 1000px;
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

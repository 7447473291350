<template>
  <div class="bg-blue-200">
    <h1>This is Search</h1>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Search'
}
</script>

<style scoped>

</style>

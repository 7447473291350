<template xmlns:wire="http://www.w3.org/1999/xhtml">
  <div class="grid grid-cols-8 grid-rows-7 grid-flow-col gap-1.5 mt-5 custom-h-inside">
    <div class="rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-2 row-span-5 overflow-auto p-6 z-0">
      <h1 class="text-o_purple-200 text-3xl font-semibold mb-2">Asset Of NFT</h1>
      <ul v-if="showAsset&&assetInfoActive" class="text-o_purple-100 text-3xl mb-4  mt-1 pt-1">
        <h1 class="text-right">
            <span class="px-6 inline-flex text-2xl leading-15 font-semibold rounded-full bg-green-100 text-green-800">
              Verified
            </span>
        </h1>
        <li>
          <span class="font-semibold text-2xl">Asset Id:</span><br>
          <div class="pb-1 text-2xl"><a :href="selectedFarmer.url" target="_blank">{{ selectedFarmer.id }}</a></div>
          <!--            <div class="pb-3.5">{{ farmer.graph_url }}</div>-->
        </li>
        <li>
          <span class="font-semibold text-2xl">Farmer Name:</span><br>
          <div class="pb-1 text-2xl">{{ selectedFarmer.name }}</div>
        </li>
        <li>
          <span class="font-semibold text-2xl">Place:</span><br>
          <div class="pb-1 text-2xl">{{ selectedFarmer.place }}</div>
        </li>
        <li>
          <span class="font-semibold text-2xl">Product:</span><br>
          <div class="pb-1 text-2xl">{{ selectedFarmer.product }}</div>
          <span class="font-semibold text-2xl">Graph Snapshot:</span><br>
          <div id="myWindow" class="w-2/3 mt-3 rounded-2xl shadow-lg border">
            <img class="rounded-2xl" id="myContent" :src="selectedFarmer.graph_url" alt="" />
          </div>
        </li>
      </ul>
    </div>
    <div class="rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-6 row-span-full overflow-auto p-6 z-0">
<!--      <h1 class="text-o_purple-200 text-3xl font-semibold mb-3">Virtual Scene: test: "{{ nftClickDetect }}"</h1>-->
      <h1 class="text-o_purple-200 text-3xl font-semibold mb-3">Virtual Scene</h1>
      <p class="mt-2 pb-3.5 text-xl text-o_purple-100"> Click a item of packaged rice at the 3D scene below, then the left Assets of NFT panel will show the corresponding details.</p>
      <!--        <div class="sketchfab-embed-wrapper w-full h-full" v-show="sceneActive"> <iframe class="w-full h-full" title="uploads_files_1986768_foodSacksCombined_1" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/7eeb3ee21c1048b18fc867a93b8068dc/embed?autostart=1&camera=0&preload=1&ui_hint=2&dnt=1"> </iframe> </div>-->
      <!--        <div class="sketchfab-embed-wrapper w-full h-full" v-show="sceneActive"> <iframe class="w-full h-full" title="Grain Warehouse Scene" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/ca54d955fb1b4e5bb3636e1af04fe76d/embed?autostart=1&camera=0&preload=1&ui_hint=2"> </iframe> </div>-->
      <!--        <div class="sketchfab-embed-wrapper w-full h-full" v-show="sceneActive"> <iframe class="w-full h-full" title="Grain Warehouse Scene" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/ca54d955fb1b4e5bb3636e1af04fe76d/embed?ui_hint=2&dnt=1"> </iframe> </div>-->
      <!--        <div class="sketchfab-embed-wrapper w-full h-full" v-show="sceneActive"> <iframe class="w-full h-full" title="Grain Warehouse Scene" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/282641aa53384fc2b33a1e46807f14cd/embed?dnt=1"> </iframe> </div>-->
      <div class="h-full relative">
        <div v-show="virtualSceneActive" id="loadingRicePackaged" wire:loading class="absolute top-0 left-0 right-0 bottom-0 w-full h-full z-50 overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
          <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 id="loadingRicePackagedProgress" class="text-center text-white text-xl font-semibold">Loading Scene: 0%</h2>
          <p class="w-1/3 text-center text-white">It will take some time, please do not close this page or do other things</p>
        </div>
        <canvas v-show="!virtualSceneActive" class="bg-white absolute top-0 left-0 right-0 bottom-0 w-full h-full z-55"></canvas>
        <canvas @click="rayCast" id="myPackagedRiceWarehouse" ref="myPackagedRiceWarehouseRef" class="w-full h-full z-60"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
// import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
// import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
// import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min'
let myPackagedRiceWarehouse
let scene
let renderer
let width
let height
let camera
let raycaster
let loader
let clock
let mixer
let dracoLoader
// let glbTemp = {}
const baseUrl = 'https://www.for-paper-static.binyi.fun/statics/'
let count = 0
// let loadingStatusRicaPackaged = false
// let reflectionCube
export default {
  name: 'PackagedRiceWarehouse',
  data: function () {
    return {
      assetInfoActive: false,
      virtualSceneActive: false,
      isLoadingRicaPackaged: false,
      select: 'xxx',
      showAsset: false,
      farmers: {
        '2020-C1_Lu-Ming Rice_Tian-Shang Chang': {
          id: '2020-C1_Lu-Ming Rice_Tainan 16 #1',
          graph_url: 'https://www.for-paper-static.binyi.fun/statics/graph_snapshot_2020-C1_Lu-Ming Rice_Tian-Shang Chang.png',
          url: 'https://www.akaswap.com/akaobj/3682',
          name: 'Tian-Shang Chang',
          place: 'Tian-Shang Chang_Organic Farm',
          product: '2020-C1_Lu-Ming Rice_Tainan 16'
        },
        '2020-C1_Lu-Ming Rice_Jin-Shi Hsieh': {
          id: '2020-C1_Lu-Ming Rice_Tainan 16 #2',
          graph_url: 'https://www.for-paper-static.binyi.fun/statics/graph_snapshot_2020-C1_Lu-Ming Rice_Jin-Shi Hsieh.png',
          url: 'https://www.akaswap.com/akaobj/3684',
          name: 'Jin-Shi Hsieh',
          place: 'Jin-Shi Hsieh_Organic Farm',
          product: '2020-C1_Lu-Ming Rice_Tainan 16'
        },
        '2020-C1_Lu-Ming Rice_Cheng-Po Hsu': {
          id: '2020-C1_Lu-Ming Rice_Tainan 16 #3',
          graph_url: 'https://www.for-paper-static.binyi.fun/statics/graph_snapshot_2020-C1_Lu-Ming Rice_Cheng-Po Hsu.png',
          url: 'https://www.akaswap.com/akaobj/3686',
          name: 'Cheng-Po Hsu',
          place: 'Cheng-Po Hsu_Organic Farm',
          product: '2020-C1_Lu-Ming Rice_Tainan 16'
        },
        '2020-C1_Lu-Ming Rice_Jin-Nan Hsieh': {
          id: '2020-C1_Lu-Ming Rice_Tainan 16 #4',
          graph_url: 'https://www.for-paper-static.binyi.fun/statics/graph_snapshot_2020-C1_Lu-Ming Rice_Jin-Nan Hsieh.png',
          url: 'https://www.akaswap.com/akaobj/3688',
          name: 'Jin-Nan Hsieh',
          place: 'Jin-Nan Hsieh_Organic Farm',
          product: '2020-C1_Lu-Ming Rice_Tainan 16'
        }
      },
      currentFarmer: {
        id: '',
        graph_url: '',
        url: '',
        name: '',
        place: '',
        product: ''
      }
    }
  },
  computed: {
    nftClickDetect: function () {
      return this.select
    },
    selectedFarmer: function () {
      return this.currentFarmer
    }
  },
  methods: {
    rayCast: function (e) {
      var intersects = []
      var mouse = new THREE.Vector2()

      // const width = this.$refs.myPackagedRiceWarehouseRef.clientWidth
      // const height = this.$refs.myPackagedRiceWarehouseRef.clientHeight
      // mouse.x = 2 * (e.clientX / width) - 1
      // mouse.y = 1 - 2 * (e.clientY / height)
      // 下面這個rect很重要，他可以改善位置抓錯的問題，失誤是因為沒有抓到relative position of the canvas dom in your HTML document
      const rect = renderer.domElement.getBoundingClientRect()
      // 重新調整合校正點擊的x與y，再拿去做修正做raycast
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top
      mouse.x = 2 * (x / width) - 1
      mouse.y = 1 - 2 * (y / height)
      // 這是更新攝影機的世界座標
      camera.updateMatrixWorld()
      raycaster = new THREE.Raycaster()
      // update the picking ray with the camera and mouse position
      // 這個會更新原點與方向，來更新射線ray，因此每次都要做
      raycaster.setFromCamera(mouse, camera)

      // calculate objects intersecting the picking ray，第二個參數為選擇性參數recursive，一般這種就是會向下檢查children(default false)
      // 他要比對看看是否點擊的內容與scene內容交錯
      // 返回會有以下內容: distance射線從起點與相交點距離/point與世界座標交叉點/face相交的面/faceIndex相交的面的索引/indices組成相交面的索引
      // 比較重要的是"object 相較的物件"
      intersects = raycaster.intersectObjects(scene.children)
      // console.log('intersect: ')
      // console.log(intersects)
      // console.log(intersects[0])
      if (intersects[0]) {
        var object = intersects[0].object
        // console.log('object: is')
        // console.log(object)
        // console.log(object.parent.name)
        this.select = object.parent.name
        // if (object.parent.name === 'rice1') {
        //   console.log('click successfully')
        // }
        if (this.farmers[this.select]) {
          this.showAsset = true
          this.currentFarmer.id = this.farmers[this.select].id
          this.currentFarmer.name = this.farmers[this.select].name
          this.currentFarmer.place = this.farmers[this.select].place
          this.currentFarmer.product = this.farmers[this.select].product
          this.currentFarmer.url = this.farmers[this.select].url
          this.currentFarmer.graph_url = this.farmers[this.select].graph_url
        }
      }
      // for (var i = 0; i < intersects.length; i++) {
      //   intersects[i].object.material.color.set(0xff0000)
      // }
    }
  },
  async mounted () {
    // 取得scene DOM element所在之處，利用id取得
    myPackagedRiceWarehouse = document.getElementById('myPackagedRiceWarehouse')
    // console.log('show myPackagedRiceWarehouse')
    // console.log(myPackagedRiceWarehouse)
    // 建立scene instance
    scene = new THREE.Scene()
    // console.log('show scene')
    // console.log(scene)
    // --------------------render設置渲染區域、建立尺寸、背景顏色、與顯示品質-------------------------------------------------------
    // renderer可以渲染scene到指定的DOM元素上，以WebGLRenderer作為renderer
    // renderer可以去設定: width與height(利用setSize) 、background color(利用setClearColor)
    // 利用devicePixelRatio to improve the quality of the image (可以調整輸出的image品質，會去配合螢幕的物理像素分辨率來設置)
    renderer = new THREE.WebGLRenderer({
      canvas: myPackagedRiceWarehouse,
      autoClearColor: true
    })
    // renderer.autoClearColor = false
    // renderer.autoClear = false
    // renderer.alpha = true
    // 動態抓取element寬高的function
    width = this.$refs.myPackagedRiceWarehouseRef.clientWidth
    height = this.$refs.myPackagedRiceWarehouseRef.clientHeight
    renderer.setSize(width, height)
    renderer.setPixelRatio(window.devicePixelRatio)
    // console.log('show renderer')
    // console.log(renderer)
    // color代號是從0x後開始，有些會寫成#號，ff3300是橘紅色
    // renderer.setClearColor(0xbfd1e5)
    // --------------------Lights設置---------------------------------------------------------------------------------------
    // 這可能是物件面的自發光
    renderer.toneMappingExposure = 1
    // intensity可以0~2，這邊設置為0.3，color常規一般為白: 0xffffff
    const ambientLight = new THREE.AmbientLight(0xffffff, 2)
    ambientLight.position.set(0, 0.5, 0)
    scene.add(ambientLight)
    // intensity可以0~4，這邊設置為0.8*Math.PI，color常規一般為白: 0xffffff
    const directLight = new THREE.DirectionalLight(0xffffff, 2)
    directLight.position.set(0.5, 0, 0.866)
    scene.add(directLight)
    const directLight2 = new THREE.DirectionalLight(0xffffff, 2)
    directLight2.position.set(-0.5, 0, -0.866)
    scene.add(directLight2)
    renderer.physicallyCorrectLights = true
    renderer.outputEncoding = THREE.sRGBEncoding
    // --------------------添加perspective camera視角相機(呈現要的畫面角度、位置)-------------------------------------------------
    // fov參數: 相機的垂直視野，超過視野不會被渲染
    // aspect參數: 視野外觀比裡，一般width/height
    // near參數: 相機近平面，若比此平面更近則不渲染
    // far參數: 相機遠平面，若比此平面更遠則不渲染 (near、far兩平面建構一個最進、最遠的能渲染空間範圍)

    camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
    // THREE object instance都可以下列方式來設置位置
    camera.position.set(-5, 3.5, 1)
    camera.setFocalLength(20)

    // console.log('show camera')
    // console.log(camera)
    // -------------------相機旋轉縮放控制器設置(目前似乎有問題因為沒使用到，可能要查看一下參考資料)----------------------------------
    // 這稱作軌道控制器，可以控制camera的移動於orbit軌道上(會環繞在一個sphere)，然後scroll mouse can zoom in or out
    // 為額外的lib需要引入: import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
    // Orbitcontrol object instance上有許多細節可以設置ref: https://medium.com/%E5%B0%8F%E5%BD%A5%E5%BD%A5%E7%9A%84%E5%89%8D%E7%AB%AF%E4%BA%94%E5%9B%9B%E4%B8%89/threejs-%E5%88%9D%E5%BF%83%E8%80%85%E5%85%A5%E9%96%80-%E4%B8%83-%E6%8E%A7%E5%88%B6%E5%99%A8-orbitcontrols-7b8d7c74a174
    // 應該有像是只能轉到多少角度於水平 or 垂直，以及縮放的範圍，跟是否開放縮放、旋轉，也有自動旋轉...等細節，上述ref，也可以網路用關鍵字搜尋
    // 下面是最基本的設置方式
    const orbit = new OrbitControls(camera, renderer.domElement)
    // 下面那條是硬加的，因為編譯eslint要求variable一定要被使用的風格，只好硬去設定enable控制器(他default本身就是true)
    // 不過也可以發現可以在某些情況做鎖住，譬如跳窗出來的時候或許就能做類似操作
    orbit.enabled = true
    orbit.minDistance = 2
    orbit.maxDistance = 6
    // 是以PI來算角度
    orbit.minPolarAngle = Math.PI * (1 / 6)
    orbit.maxPolarAngle = Math.PI * 0.5
    orbit.target.set(2, 1.5, -0.5)
    orbit.update()
    // --------------------GUI操作介面---------------------------------------------------------------------------------------
    // 只設置width為300(其實這在介面中很常見，因為高無限)，可以建立一個panel儀錶板，並增添buttons給他做操作
    // 我之後可能會將button對應的function改用click物件來觸發該functions，不過先用這個來針對目標觸發期望動畫
    // const gui = new GUI({ width: 300, autoPlace: false })
    // const customContainer = document.getElementById('myGUI')
    // customContainer.append(gui.domElement)
    // console.log('set GUI')
    // console.log(gui)
    // --------------------GUI新增動作Action相關操作---------------------------------------------------------------------------
    // actions為一個放置不同many different actions to this empty object named 'actions'
    // 像是教學的例子有4個actions，TPose、Idle閒置、Walk走路、Sit坐著(也就是說可以有多個靜態、動態的動作，並可以去做切換)
    // const actions = {}
    // 這也是常見的temp variable，會去紀錄選擇，這邊是紀錄當前選擇的selected actions
    // 之後可以利用這種actions的temp去做切換，做一個動作的循環到結束
    // eslint-disable-next-line camelcase
    // let current_action
    // 這個object有一個"pause - resume" property，對應一個function
    // 該function會去pause暫停或resume恢復某個current_action中存放的action
    // 假如是我們的專案之後可能會有增加更多的property對應不同的models of different equipments
    // eslint-disable-next-line camelcase
    // const pause_resume = {
    //   'pause - resume': () => {
    //     // 等於這是一個暫停/恢復函數，只要current_action當前有選中一個action，則此function會依據此
    //     // 會進行一個互斥的切換，初始應該是false(空值)，所以第一次被觸發會將pause設置成true
    //     // 這個current_action設置成object很聰明，因為除了記錄動作，也有是否運作中的狀態(If it is paused與否)
    //     // eslint-disable-next-line camelcase
    //     if (current_action) {
    //       current_action.paused = !current_action.paused
    //     }
    //   }
    // }
    // 於GUI中產生一個按鈕並對應一個function
    // add把pause_resume中存放的pause - resume的func添加到gui之中，並產生一個button對應此function
    // console.log('gui.add')
    // gui.add(pause_resume['pause - resume'])
    // console.log(gui)

    // 這可於gui panel上產生一個scroll options下拉選單，會顯示該下拉式選單名稱，叫做Actions，尚未設置options
    // 之後應該是可以添加actions到裡面去，會影響current_action
    // 我預期點立牌會有該equipment對應的operation的說明，而這個option則是選equipment，有另外button可以觸發他移動
    // const folder = gui.addFolder('Actions')
    // console.log('folder set')
    // let model1
    // let p1 = lnpoadModel('').then()
    // GLTFLoader instance會負責load models with their actions之後會置入insert to scene
    loader = new GLTFLoader()
    // console.log('loader set')
    // animate the selected action動畫動作的一個necessary parameter(可能跟時間有一些關係，或許是運作的速率、長度...)
    clock = new THREE.Clock()
    // console.log('clock set')
    // 作為identity and control actions識別、控制動作，之後會配合THREE的AnimationMixer動畫混合器，他的參數為動畫root物件
    // 用於儲存所有動畫的數據(因此被說來用作identity and control actions)，可以混合多組動畫actions
    // 而他其中並沒有什麼properties語methods，會透過一個叫做AnimationAction來針對Mixer內容進行操作(撥放、暫停或終止某個AnimationClip動畫片段
    // ref: https://ithelp.ithome.com.tw/articles/10252394
    // eslint-disable-next-line no-unused-expressions
    // mixer
    // mixer相關說明---------------------------------------------------------------------------------------------------------
    // 入上他是作為animation action操作用途，會用來承接從scene取出的actions，透過new THREE.AnimationMixer(glb.scene)此instance
    // 譬如他提供動畫停止的func: stopAllAction，這很適合作為動畫間的切換，使得動畫之間不會衝突

    // Optional: Provide a DRACOLoader instance to decode compressed mesh data
    dracoLoader = new DRACOLoader()
    // dracoLoader.setDecoderPath('/examples/js/libs/draco/')
    dracoLoader.setDecoderPath('dracoDecoder/')
    dracoLoader.setDecoderConfig({ type: 'js' })
    loader.setDRACOLoader(dracoLoader)
    // 操作器為json會存放不同的操作器，為cube
    // var controller = {}
    async function loadByGlbName (path, name) {
      loader.load(
        // 用相對位置指出.glb檔案所在位置
        // eslint-disable-next-line import/no-absolute-path
        // 一般vueJS的static resources會放在public之中，然後可以用下面path方式直接獲取到，就直接dir/path/fileName
        path,
        (glb) => {
          mixer = new THREE.AnimationMixer(glb.scene)
          // console.log(mixer)
          // console.log('glb is below:')
          // console.log(glb)
          // forLoop會exhaust all the actions inside the scene
          // value會有name(就是在blender中action的name)
          // console.log('animations')
          // console.log(glb.animations)
          // for (const value of glb.animations) {
          //   const action = mixer.clipAction(value)
          //   // 每個action的name都給他對應一個function，這邊是做一個general
          //   // 之後應該可以再開一個object可能作為其他用途，或者針對某些action
          //   actions[value.name] = () => {
          //     // 首先停止其他action ("之後要確定停止是否位置也會停住
          //     // mixer.stopAllAction()
          //
          //     // 下面這個是互斥設計，若想要可以同時則可以disable
          //     // if (current_action) {
          //     //   console.log(current_action)
          //     //   current_action.paused = true
          //     //   action.paused = false
          //     // }
          //
          //     // 下面這條註銷掉的話似乎會一直重複循環
          //     // action.setLoop(THREE.LoopOnce);
          //     action.clampWhenFinished = true
          //     action.loop = THREE.LoopOnce
          //     // 播放該action ("可能要做雙向action at path，各自獨立名稱")
          //     action.play()
          //     // ref https://www.codeleading.com/article/21441734023/
          //     // actions.forEach(function (obj) {
          //     //   if (obj == action) {
          //     //     obj.play()
          //     //   } else {
          //     //     obj.stop()
          //     //   }
          //     // })
          //     // 當前運作action變成此action
          //     // eslint-disable-next-line camelcase
          //     current_action = action
          //   }
          //   // folder是gui編輯用的，gui上的options透過輸入json object list以及，名稱
          //   // 他會要求一個function的map list(可以每次新增新的action name就再更新
          //
          //   // 下面這個是reset功能，reset and stop
          //   folder.add(actions, value.name)
          //
          //   actions['reset' + '_' + value.name] = () => {
          //     console.log('Helloooooooooo~')
          //     action.reset()
          //     action.stop()
          //     // action.paused = false
          //   }
          //   folder.add(actions, 'reset' + '_' + value.name)
          // }
          // 要指定name，之後才能依據點擊的內容來得知要顯示什麼內容
          glb.scene.name = name
          // console.log(glb.scene.name)
          // console.log(glb.scene)
          // glbTemp[name] = glb.scene.children
          // testCubeGroup.add(controller[name])
          scene.add(glb.scene)
          // scene.add(testCubeGroup)
          animate()
          // 本身這裡面就是一個callback function，能到達這邊表示此scene已經處理上載完畢，屬於非同步
          // 因此設計一個count給大家共同使用於global，每當一個scene處理完畢，就+1，最終成功就把讀取中hidden
          count++
          // console.log('Loading Scene: ' + (count / 5) * 100 + '%')
          document.getElementById('loadingRicePackagedProgress').innerHTML = 'Loading Scene: ' + (count / 5) * 100 + '%'
          if (count === 5) {
            document.getElementById('loadingRicePackaged').style.visibility = 'hidden'
          }
        }
      )
    }
    // loader.load(
    //   // 用相對位置指出.glb檔案所在位置
    //   // eslint-disable-next-line import/no-absolute-path
    //   // 一般vueJS的static resources會放在public之中，然後可以用下面path方式直接獲取到，就直接dir/path/fileName
    //   '3dModels/ricePackage3_applyModify.glb',
    //   (glb) => {
    //     mixer = new THREE.AnimationMixer(glb.scene)
    //     console.log(mixer)
    //     // forLoop會exhaust all the actions inside the scene
    //     // value會有name(就是在blender中action的name)
    //     console.log('animations')
    //     console.log(glb.animations)
    //     for (const value of glb.animations) {
    //       const action = mixer.clipAction(value)
    //       // 每個action的name都給他對應一個function，這邊是做一個general
    //       // 之後應該可以再開一個object可能作為其他用途，或者針對某些action
    //       actions[value.name] = () => {
    //         // 首先停止其他action ("之後要確定停止是否位置也會停住
    //         // mixer.stopAllAction()
    //
    //         // 下面這個是互斥設計，若想要可以同時則可以disable
    //         // if (current_action) {
    //         //   console.log(current_action)
    //         //   current_action.paused = true
    //         //   action.paused = false
    //         // }
    //
    //         // 下面這條註銷掉的話似乎會一直重複循環
    //         // action.setLoop(THREE.LoopOnce);
    //         action.clampWhenFinished = true
    //         action.loop = THREE.LoopOnce
    //         // 播放該action ("可能要做雙向action at path，各自獨立名稱")
    //         action.play()
    //         // ref https://www.codeleading.com/article/21441734023/
    //         // actions.forEach(function (obj) {
    //         //   if (obj == action) {
    //         //     obj.play()
    //         //   } else {
    //         //     obj.stop()
    //         //   }
    //         // })
    //         // 當前運作action變成此action
    //         // eslint-disable-next-line camelcase
    //         current_action = action
    //       }
    //       // folder是gui編輯用的，gui上的options透過輸入json object list以及，名稱
    //       // 他會要求一個function的map list(可以每次新增新的action name就再更新
    //
    //       // 下面這個是reset功能，reset and stop
    //       folder.add(actions, value.name)
    //
    //       actions['reset' + '_' + value.name] = () => {
    //         console.log('Helloooooooooo~')
    //         action.reset()
    //         action.stop()
    //         // action.paused = false
    //       }
    //       folder.add(actions, 'reset' + '_' + value.name)
    //     }
    //
    //     scene.add(glb.scene)
    //     animate()
    //   }
    // )
    count = 0
    await loadByGlbName(baseUrl + 'warehouse.glb', 'warehouse')
    await loadByGlbName(baseUrl + '1_2020-C1_Lu-Ming Rice_Tian-Shan_2020-C1_Lu-Ming Rice_Tainan 16.glb', '2020-C1_Lu-Ming Rice_Tian-Shang Chang')
    await loadByGlbName(baseUrl + '2_ 2020-C1_Lu-Ming Rice_Jin-Shi _2020-C1_Lu-Ming Rice_Tainan 16.glb', '2020-C1_Lu-Ming Rice_Jin-Shi Hsieh')
    await loadByGlbName(baseUrl + '3_2020-C1_Lu-Ming Rice_Cheng-Po_2020-C1_Lu-Ming Rice_Tainan 16.glb', '2020-C1_Lu-Ming Rice_Cheng-Po Hsu')
    await loadByGlbName(baseUrl + '4_2020-C1_Lu-Ming Rice_Jin-Nan H_2020-C1_Lu-Ming Rice_Tainan 16.glb', '2020-C1_Lu-Ming Rice_Jin-Nan Hsieh')
    // --------------------環景設置------------------------------------------------------------------------------------------
    const cubePath = 'cubeMapping/'
    var reflectionCube = new THREE.CubeTextureLoader()
    reflectionCube.setPath(cubePath)
    // 這有時候沒顯示出來全黑，是非同步問題，下列這個回呼似乎可以改善這問題
    reflectionCube.load([
      '草原_日昇_1_px.jpg',
      '草原_日昇_1_nx.jpg',
      '草原_日昇_1_py.jpg',
      '草原_日昇_1_ny.jpg',
      '草原_日昇_1_pz.jpg',
      '草原_日昇_1_nz.jpg'
    ], texture => {
      // console.log('got texture')
      scene.background = texture
    })
    // console.log('show cube:')
    // console.log(reflectionCube)
    reflectionCube.encoding = THREE.sRGBEncoding
    // scene.background = reflectionCube
    // scene.background = new THREE.Color(0xbfd1e5)
    scene.background = new THREE.Color(0x2D70A9)
    // console.log('show background')
    // console.log(scene.background)
    // var skyBoxGeo = new THREE.BoxGeometry(10000, 10000, 10000)
    // var pathArray = [
    //   cubePath + 'skyMountain_px.jpg',
    //   cubePath + 'skyMountain_nx.jpg',
    //   cubePath + 'skyMountain_py.jpg',
    //   cubePath + 'skyMountain_ny.jpg',
    //   cubePath + 'skyMountain_pz.jpg',
    //   cubePath + 'skyMountain_nz.jpg'
    // ]
    // var materialArray = pathArray.map(
    //   imagePath => {
    //     // eslint-disable-next-line new-cap
    //     const texture = new THREE.TextureLoader().load(imagePath)
    //     console.log('show path texture: ' + texture)
    //     console.log(texture)
    //     return new THREE.MeshBasicMaterial({ map: texture, side: THREE.BackSide })
    //   }
    // )
    // var skyBox = new THREE.Mesh(skyBoxGeo, materialArray)
    // scene.add(skyBox)
    // eslint-disable-next-line no-use-before-define
    // --------------------------------------------------------------------------------------------------------------------
    // console.log('show controllers')
    // console.log(controller)
    // var group = scene.getObjectByName('rice1')
    // console.log('show group name:')
    // console.log(group)
    // console.log('show all scene below:')
    // console.log(scene)
    // --------------------Raycaster偵測物件被點擊用途-------------------------------------------------------------------------
    // const raycaster = new THREE.Raycaster()
    // var intersects = []
    // myPackagedRiceWarehouse.addEventListener('click', (e) => {
    //   console.log('click event')
    //   raycast(e)
    // })
    // function raycast (e) {
    //   var mouse = new THREE.Vector2()
    //
    //   // const width = this.$refs.myPackagedRiceWarehouseRef.clientWidth
    //   // const height = this.$refs.myPackagedRiceWarehouseRef.clientHeight
    //   // mouse.x = 2 * (e.clientX / width) - 1
    //   // mouse.y = 1 - 2 * (e.clientY / height)
    //   // 下面這個rect很重要，他可以改善位置抓錯的問題，失誤是因為沒有抓到relative position of the canvas dom in your HTML document
    //   const rect = renderer.domElement.getBoundingClientRect()
    //   // 重新調整合校正點擊的x與y，再拿去做修正做raycast
    //   const x = e.clientX - rect.left
    //   const y = e.clientY - rect.top
    //   mouse.x = 2 * (x / width) - 1
    //   mouse.y = 1 - 2 * (y / height)
    //   // mouse.x = 2 * (e.clientX / window.width) - 1
    //   // mouse.y = 1 - 2 * (e.clientY / window.height)
    //   camera.updateMatrixWorld()
    //   // update the picking ray with the camera and mouse position
    //   // 這個會更新原點與方向，來更新射線ray，因此每次都要做
    //   raycaster.setFromCamera(mouse, camera)
    //
    //   // calculate objects intersecting the picking ray，第二個參數為選擇性參數recursive，一般這種就是會向下檢查children(default false)
    //   // 他要比對看看是否點擊的內容與scene內容交錯
    //   // 返回會有以下內容: distance射線從起點與相交點距離/point與世界座標交叉點/face相交的面/faceIndex相交的面的索引/indices組成相交面的索引
    //   // 比較重要的是"object 相較的物件"
    //   intersects = raycaster.intersectObjects(scene.children)
    //   console.log('show cube group')
    //   console.log(testCubeGroup)
    //   console.log('intersect: ')
    //   console.log(intersects)
    //   console.log(intersects[0])
    //   if (intersects[0]) {
    //     var object = intersects[0].object
    //     console.log('object: is')
    //     console.log(object)
    //     console.log(object.parent.name)
    //     selectTemp = object.parent.name
    //     if (object.parent.name === 'rice1') {
    //       console.log('click successfully')
    //     }
    //   }
    //   for (var i = 0; i < intersects.length; i++) {
    //     intersects[i].object.material.color.set(0xff0000)
    //   }
    // }
    // ----------------------------------------------------------------------------------------------------------------------
    // console.log('show loader')
    // console.log(loader)
    // actions.reset = () => {
    //   console.log('Helloooooooooo~')
    // }
    const animate = function () {
      requestAnimationFrame(animate)
      mixer.update(clock.getDelta())
      renderer.render(scene, camera)
    }
    // animate()
  }
}
</script>

<style scoped>
.custom-h-inside {
  height: 1200px;
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
